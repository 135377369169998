import { BASE_DOMAIN } from "../constants/app";
import { makeGet, makePost } from "./index";

const API_URL = {
    USERS: () => `${BASE_DOMAIN}/v1/users`,
    USERS_WITH_STATISTICS: (page: number, rowsPerPage: number, searchValue = "") =>
        `${BASE_DOMAIN}/v1/users/statistics?page=${page}&rowsPerPage=${rowsPerPage}&searchValue=${searchValue}`,
    USERS_PER_MONTH: () => `${BASE_DOMAIN}/v1/users-per-month`,
    TUNNELS_WITH_DEVICES_AND_PORTS: (userId: string) =>
        `${BASE_DOMAIN}/v1/users/tunnels-with-devices-and-ports?userId=${userId}`,
    RELOAD_TUNNEL: () => `${BASE_DOMAIN}/v1/tunnel/admin/reload`,
};

export function getUsersWithStatistics(page: number, rowsPerPage: number, searchValue?: string) {
    return makeGet(API_URL.USERS_WITH_STATISTICS(page, rowsPerPage, searchValue), {});
}

export function getUsersPerMonth() {
    return makeGet(API_URL.USERS_PER_MONTH(), {});
}

export function getUserTunnelsWithDevicesAndPorts(userId: string) {
    return makeGet(API_URL.TUNNELS_WITH_DEVICES_AND_PORTS(userId), {});
}
export function reloadTunnel(tunnelId: string) {
    return makePost(API_URL.RELOAD_TUNNEL(), { tunnelId }, {});
}
