import React, { useContext } from "react";
import { withRouter, Redirect } from "react-router-dom";

import PropTypes from "prop-types";
import { UserContext } from "../context";

export default (
    options = {
        authenticatedGuard: true
    }
) => (Component: any) => {
    function WithAuthGuard(props: any) {
        const { authenticatedGuard } = options;
        const user = useContext(UserContext);
        const { location } = props;
        if (authenticatedGuard) {
            if (user) {
                return <Component {...props} />;
            }
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { redirectTo: location }
                    }}
                />
            );
        }
        if (!user) {
            return <Component {...props} />;
        }
        return <Redirect to="/" />;
    }

    WithAuthGuard.propTypes = {
        location: PropTypes.shape({
            search: PropTypes.string.isRequired
        }).isRequired
    };

    WithAuthGuard.displayName = `WithAuthGuard(${Component.displayName || Component.name})`;
    return withRouter(WithAuthGuard);
};
