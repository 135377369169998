import { validate as emailValidator } from "email-validator";
// copy from 'node-service', as node-service can not be imported with webpack
// @todo refactor

export const EMPTY = "EMPTY";
export const INVALID_EMAIL = "INVALID_EMAIL";
export const INVALID_PASSWORD = "Invalid Password (password should be at least 8 chars)";

export function validateEmail(email: string) {
    if (!email || !email.trim()) {
        return EMPTY;
    }
    if (!emailValidator(email)) {
        return INVALID_EMAIL;
    }
    return true;
}

export function validateRequire(value: string) {
    return value && value.trim() !== "";
}

export function validateUsername(username: string) {
    if (!username || !username.trim() || (username.trim() && username.trim().length < 5)) {
        return false;
    }
    const regex = /^[a-z][a-z0-9]+$/i;
    return regex.test(username.trim());
}

export function validatePassword(password: string) {
    if (!password || !password.trim()) {
        return EMPTY;
    }
    if (password.trim().length < 8) {
        return INVALID_PASSWORD;
    }
    return true;
}
