import React, { useEffect, useState } from "react";
import withStyles, { ClassNameMap } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Theme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        toolbar: {
            height: 64,
            backgroundColor: palette.primary.contrastText,
            color: palette.primary.main
        },
        textField: {
            marginLeft: spacing(1),
            marginRight: spacing(1),
            width: 200
        }
    });

const Filters = ({
    classes,
    filtersChange,
    hidePingMessages,
    hidePingSuccessMessages,
    hidePingMessagesChanges,
    hidePingSuccessMessagesChanges
}: {
    classes: Partial<ClassNameMap<string>>;
    filtersChange: (searchByTopic: string, searchByMessageType: string, isRegex: boolean) => void;
    hidePingMessages: boolean;
    hidePingSuccessMessages: boolean;
    hidePingMessagesChanges: (hide: boolean) => void;
    hidePingSuccessMessagesChanges: (hide: boolean) => void;
}) => {
    const [searchByTopic, setSearchByTopic] = useState("");
    const [searchByMessageType, setSearchByMessageType] = useState("");
    const [isRegex, setIsRegex] = useState(false);

    useEffect(() => {
        const handler = function(event: KeyboardEvent) {
            if (event.key === "Enter") {
                filtersChange(searchByTopic, searchByMessageType, isRegex);
            }
        };
        window.addEventListener("keydown", handler);
        return () => {
            window.removeEventListener("keydown", handler);
        };
    }, []);

    const handleSearchByTopicValueChange = (e: any) => {
        setSearchByTopic(e.target.value);
    };
    const handleSearchByMessageTypeValueChange = (e: any) => {
        setSearchByMessageType(e.target.value);
    };
    const handleRegexChange = (e: any) => {
        setIsRegex(e.target.checked);
    };

    const onSearchBtnClick = () => {
        filtersChange(searchByTopic, searchByMessageType, isRegex);
    };

    return (
        <Toolbar variant="regular" className={classes.toolbar}>
            <Typography color="inherit" className={classes.title}>
                Filters
            </Typography>
            <TextField
                label="Search by topic"
                className={classes.textField}
                value={searchByTopic}
                onChange={handleSearchByTopicValueChange}
                margin="normal"
            />
            <FormControlLabel
                control={<Checkbox checked={isRegex} onChange={handleRegexChange} value="regex" color="primary" />}
                label="Regex"
            />
            <TextField
                label="Search by message type"
                className={classes.textField}
                value={searchByMessageType}
                onChange={handleSearchByMessageTypeValueChange}
                margin="normal"
            />
            <Button color="primary" variant="contained" className={classes.button} onClick={onSearchBtnClick}>
                Search
            </Button>
            <div style={{ flex: 1 }} />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={hidePingMessages}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                            hidePingMessagesChanges(checked)
                        }
                        color="primary"
                    />
                }
                label="Hide ping messages"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={hidePingSuccessMessages}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                            hidePingSuccessMessagesChanges(checked)
                        }
                        color="primary"
                    />
                }
                label="Hide ping success messages"
            />
        </Toolbar>
    );
};

export default withStyles(styles)(Filters);
