import { BASE_DOMAIN } from "../constants/app";
import { makeDelete, makeGet, makePatch, makePost, upload, makePut } from "./index";
import { IOrganization } from "../interfaces";

const API_URL = {
    ORGANIZATION: () => `${BASE_DOMAIN}/v1/admin/organization`,

};

export function getOrganizations({ search, filters }: { search?: string, filters?: [{ property: string, value: any }] }): Promise<{ data: IOrganization[] }> {
    let url = API_URL.ORGANIZATION();
    const fl = getFilters(filters);
    let sep = '?';
    if (search) {
        url += sep + `search=${search}`
        sep = '&'
    }
    if (fl) {
        url += sep + fl
    }
    return makeGet(url, {});
}

export function addOrganizations(name: string, description: string) {
    return makePost(API_URL.ORGANIZATION(), { name, description }, {})
}

function getFilters(filters?: [{ property: string, value: any }]) {
    if (!filters) {
        return null;
    }
    let res = [];
    for (let i = 0; i < filters.length; i++) {
        if (Array.isArray(filters[i].value)) {
            for (let j = 0; j < filters[i].value.length; j++) {
                res.push(`filters[${i}][property]=${filters[i].property}&filters[${i}][value][${j}]=${filters[i].value[j]}`)
            }

        } else {
            res.push(`filters[${i}][property]=${filters[i].property}&filters[${i}][value]=${filters[i].value}`)
        }
    }
    return res.join('&');
}
