import React, { useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { FormControlLabel, Checkbox, Select, MenuItem, ListItemText, IconButton } from "@material-ui/core";
import { allConfigs } from "./rolesTable";
import { IOrganization } from "../../interfaces";
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import MultiSelectDialog from "../shared/multiSelectDialog";
import { ServiceMethodsContext } from "../../context/role";
import { IMethod } from "../../interfaces/authorities";

const styles = (theme: Theme) =>
    createStyles({
        addRoleDialogContent: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 450,
        },
        formControl: {
            margin: 10,
            flex: 1,
            width: "100%",
        },
    });

const AddRoleDialog = ({
    classes,
    isOpen,
    handleClose,
    handleAdd,
    fullScreen,
    addRoleError,
    allOrganizations,
}: {
    classes: Partial<ClassNameMap<string>>;
    isOpen: boolean;
    handleClose: () => void;
    handleAdd: (data: any) => void;
    fullScreen?: boolean;
    addRoleError: string;
    allOrganizations: IOrganization[];
}) => {
    const [name, setName] = useState<string>("");
    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
    const [authorities, setAuthorities] = useState<string[]>([]);
    const [nameError, setNameError] = useState<string>("");
    const [publicAccess, setPublicAccess] = useState<boolean>(false);
    const [includes, setIncludes] = useState<string[]>([]);
    const [excludes, setExcludes] = useState<string[]>([]);
    const [allOrganization, setAllOrganization] = useState<boolean>(false);
    const [selectedOrgs, setSelectedOrgs] = useState<string[]>([]);
    const allMethods = useContext(ServiceMethodsContext);
    const [orgsSelectOpen, setOrgsSelectOpen] = useState<boolean>(false);

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNameError("");
        setName(e.target.value);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {
            onAddBtnClick();
        }
    };

    const inputProps = {
        onKeyDown: onKeyDown,
    };

    const onAddBtnClick = () => {
        const data = {
            name,
            authorities,
            access: publicAccess ? "public" : "system",
            uiConfig: {
                includes,
                excludes,
            },
            allOrganization,
            organizationIds: selectedOrgs,
        };
        if (isFormValid()) {
            handleAdd(data);
        }
    };

    function isFormValid() {
        if (!name) {
            setNameError("Please enter the name");
            return false;
        }
        return true;
    }

    const handleAuthoritiesSelectOpen = () => {
        setIsSelectOpen(true);
    };
    const handleAuthoritiesSelectClose = () => {
        setIsSelectOpen(false);
    };

    return (
        <Dialog fullScreen={fullScreen} open={isOpen} onClose={handleClose} aria-labelledby="add-role" maxWidth="md">
            <DialogTitle id="add-role">Add Role</DialogTitle>
            <DialogContent className={classes.addRoleDialogContent}>
                <div style={{ display: "flex", width: "100%" }}>
                    <FormControl
                        fullWidth
                        margin="dense"
                        className={classes.formControl}
                        error={!!nameError}
                        aria-describedby="add-role-name"
                    >
                        <InputLabel htmlFor="add-role-name">Name</InputLabel>
                        <Input
                            id="add-role-name"
                            autoFocus
                            type="text"
                            value={name}
                            onChange={onNameChange}
                            inputProps={inputProps}
                        />
                        {!!nameError && <FormHelperText error>{nameError}</FormHelperText>}
                        {!!addRoleError && <FormHelperText error>{addRoleError}</FormHelperText>}
                    </FormControl>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            flex: 1,
                            margin: "10px 6px 10px 10px",
                            alignItems: "center",
                        }}
                    >
                        <div style={{ whiteSpace: "nowrap" }}>{`${authorities.length} methods allowed`}</div>
                        <IconButton onClick={handleAuthoritiesSelectOpen} disableRipple size="small">
                            <ArrowDownIcon />
                        </IconButton>
                        <MultiSelectDialog
                            oneActionButton
                            title="Methods"
                            isOpen={isSelectOpen}
                            handleClose={handleAuthoritiesSelectClose}
                            handleSave={handleAuthoritiesSelectClose}
                            data={allMethods}
                            getIsChecked={(method: IMethod) => authorities.indexOf(method.name) > -1}
                            primaryTextLabel="name"
                            secondaryTextLabel="serviceName"
                            handleToggle={(method: IMethod) => {
                                const currentIndex = authorities.indexOf(method.name);
                                const newData = [...authorities];
                                if (currentIndex > -1) {
                                    newData.splice(currentIndex, 1);
                                } else {
                                    newData.push(method.name);
                                }
                                setAuthorities(newData);
                            }}
                        />
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            flex: 1,
                            margin: "10px 6px 10px 10px",
                            alignItems: "center",
                        }}
                    >
                        <div style={{ whiteSpace: "nowrap" }}>{`${selectedOrgs.length} organizations`}</div>
                        <IconButton onClick={() => setOrgsSelectOpen(true)} disableRipple size="small">
                            <ArrowDownIcon />
                        </IconButton>
                        <MultiSelectDialog
                            oneActionButton
                            title="Organizations"
                            isOpen={orgsSelectOpen}
                            handleClose={() => setOrgsSelectOpen(false)}
                            handleSave={() => setOrgsSelectOpen(false)}
                            data={allOrganizations}
                            getIsChecked={(org: IOrganization) => selectedOrgs.indexOf(org._id) > -1}
                            primaryTextLabel="name"
                            secondaryTextLabel="description"
                            handleToggle={(org: IOrganization) => {
                                const currentIndex = selectedOrgs.indexOf(org._id);
                                const newData = [...selectedOrgs];
                                if (currentIndex > -1) {
                                    newData.splice(currentIndex, 1);
                                } else {
                                    newData.push(org._id);
                                }
                                setSelectedOrgs(newData);
                            }}
                        />
                    </div>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                    <FormControl className={classes.formControl}>
                        <Select
                            fullWidth
                            multiple
                            value={includes}
                            onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                                const value: any = e.target.value;
                                setIncludes(value);
                            }}
                            displayEmpty
                            renderValue={(selected) => {
                                return selected
                                    ? Array.isArray(selected)
                                        ? `${selected.length} configs included`
                                        : `1 config included`
                                    : 0;
                            }}
                        >
                            {allConfigs.map((config: string, index: number) => (
                                <MenuItem key={`${config}-${index}`} value={config}>
                                    <Checkbox checked={includes.indexOf(config) > -1} />
                                    <ListItemText primary={config} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <Select
                            fullWidth
                            multiple
                            value={excludes}
                            onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                                const value: any = e.target.value;
                                setExcludes(value);
                            }}
                            displayEmpty
                            renderValue={(selected) => {
                                return selected
                                    ? Array.isArray(selected)
                                        ? `${selected.length} configs included`
                                        : `1 config included`
                                    : 0;
                            }}
                        >
                            {allConfigs.map((config: string, index: number) => (
                                <MenuItem key={`${config}-${index}`} value={config}>
                                    <Checkbox checked={excludes.indexOf(config) > -1} />
                                    <ListItemText primary={config} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                    <FormControlLabel
                        className={classes.formControl}
                        control={
                            <Checkbox
                                checked={publicAccess}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPublicAccess(e.target.checked)}
                                name="access"
                            />
                        }
                        label="Public access"
                    />
                    <FormControlLabel
                        className={classes.formControl}
                        control={
                            <Checkbox
                                checked={allOrganization}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setAllOrganization(e.target.checked)
                                }
                                name="access"
                            />
                        }
                        label="All organization"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={onAddBtnClick}
                    color="primary"
                    autoFocus
                    disabled={!!nameError || !name || !!addRoleError}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(AddRoleDialog);
