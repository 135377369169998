import { BASE_DOMAIN } from "../constants/app";
import { makeGet, makePost } from "./index";
import { IClientVersion } from "../interfaces";


const API_URL = {
    GET: () => `${BASE_DOMAIN}/v1/admin/client/version/node`,
    ACTIVATE: (id: string) => `${BASE_DOMAIN}/v1/admin/client/version/node/${id}`,
};

export function getClientVersions(): Promise<{ data: IClientVersion[] }> {
    return makeGet(API_URL.GET(), {});
}
export function activateClientVersions(id: string) {
    return makePost(API_URL.ACTIVATE(id), {}, {});
}

