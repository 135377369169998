import { BASE_DOMAIN } from "../constants/app";
import { makeDelete, makeGet, makePatch, makePost, upload, makePut } from "./index";
import { IPackage } from "../interfaces";

const API_URL = {
    PACKAGE: () => `${BASE_DOMAIN}/v1/packages`,
    UPLOAD_PACKAGE: () => `${BASE_DOMAIN}/v1/package/upload`,
    ACTIVATE_PACKAGE: (id: string) => `${BASE_DOMAIN}/v1//package/${id}/enable`,
    DEVICE_PACKAGES: (orgId: string) => `${BASE_DOMAIN}/v1/device-packages/${orgId}`,
    UPDATE_DELETE_PACKAGE: (id: string) => `${BASE_DOMAIN}/v1//package/${id}`,
    ADD_DEVICE_PACKAGES: (id: string) => `${BASE_DOMAIN}/v1//devicepackage/${id}`,
    CREATE_OR_UPDATE_PACKAGE_FILTER: (packageId: string) => `${BASE_DOMAIN}/v1/packages/${packageId}/filters/`
};

export function getPackages(): Promise<{ data: IPackage[] }> {
    return makeGet(API_URL.PACKAGE(), {});
}

export function getDevicePackages(orgId: string): Promise<{ data: any[] }> {
    return makeGet(API_URL.DEVICE_PACKAGES(orgId), {});
}


export function addDevicePackages(deviceId: string, packageIds: string[]): Promise<{ data: any[] }> {
    return makePost(API_URL.ADD_DEVICE_PACKAGES(deviceId), { packagesIds: packageIds }, {});
}
export function deleteDevicePackages(deviceId: string, packageIds: string[]): Promise<{ data: any[] }> {
    return makeDelete(API_URL.ADD_DEVICE_PACKAGES(deviceId), { packagesIds: packageIds }, {});
}

export function addPackage(data: any) {
    return makePost(API_URL.PACKAGE(), { data }, {});
}

export function updatePackage(id: string, data: Partial<IPackage>) {
    return makePut(API_URL.UPDATE_DELETE_PACKAGE(id), data, {});
}
export function deletePackage(id: string) {
    return makeDelete(API_URL.UPDATE_DELETE_PACKAGE(id), {}, {});
}
export function activatePackage(id: string) {
    return makePost(API_URL.ACTIVATE_PACKAGE(id), {}, {});
}

export function uploadPackage(data: FormData) {
    return upload(API_URL.UPLOAD_PACKAGE(), data);
}


export function createOrUpdatePackageFilter(packageId: string, data: FormData) {
    return upload(API_URL.CREATE_OR_UPDATE_PACKAGE_FILTER(packageId), data);
}
