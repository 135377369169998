import { BASE_DOMAIN } from "../constants/app";

const API_URL = {
    LOGIN: () => `${BASE_DOMAIN}/v1/login`,
    GET_LOGGED_IN_USER: () => `${BASE_DOMAIN}/v1/me`,
};

export function getTokens() {
    let tokens;
    try {
        tokens = JSON.parse(localStorage.getItem("tokens") || "{}");
    } catch (e) {
        tokens = {};
    }
    return tokens || {};
}

export function saveAuthToken(authToken: string) {
    const tokens = getTokens();
    tokens.authToken = authToken;
    localStorage.setItem("tokens", JSON.stringify(tokens));
}

export function clearAuthToken() {
    const tokens = getTokens();
    delete tokens.authToken;
    if (Object.keys(tokens).length) {
        localStorage.setItem("tokens", JSON.stringify(tokens));
    } else {
        localStorage.removeItem("tokens");
    }
}

export function getAuthToken() {
    const { authToken } = getTokens();

    return authToken || false;
}

export function getBearer() {
    const authToken = getAuthToken();

    if (!authToken) {
        return false;
    }
    return {
        Authorization: `Bearer ${authToken}`,
    };
}

export function getHeaders() {
    const bearer = getBearer();
    if (!bearer) {
        return {
            "Content-Type": "application/json",
        };
    }

    return {
        ...bearer,
        "Content-Type": "application/json",
    };
}

export const request = async (url: string, body: any, headers: any = {}, method = "GET") => {
    const params: any = {
        method: method,
        headers: { ...getHeaders(), ...headers },
    };
    if (body) {
        params.body = JSON.stringify(body);
    }
    try {
        const res = await fetch(url, params);
        if (res.status === 403) {
            throw new Error("403");
        }
        if (res.status === 401) {
            logout();
        }
        if (!res.ok) {
            throw new Error();
        }
        const json = await res.json();

        return json;
    } catch (e) {
        if (e && e.message === "403") throw new Error("You don't have access to resource.");
        throw new Error("Something went wrong.");
    }
};

export const upload = async (url: string, body: any, headers?: any) => {
    const params: any = {
        method: "POST",
        headers: { ...getBearer(), ...headers },
        body,
    };
    try {
        const res = await fetch(url, params);
        const json = await res.json();
        if (res.status === 403) {
            throw new Error("403");
        }
        if (res.status === 401) {
            logout();
        }
        if (!res.ok) {
            throw new Error();
        }
        return json;
    } catch (e) {
        if (e && e.message === "403") throw new Error("You don't have access to resource.");
        throw new Error("Something went wrong.");
    }
};

export const makePost = (url: string, body: any, headers: any) => request(url, body, headers, "POST");

export const makePut = (url: string, body: any, headers: any) => request(url, body, headers, "PUT");
export const makePatch = (url: string, body: any, headers: any) => request(url, body, headers, "PATCH");

export const makeGet = (url: string, headers: any) => request(url, null, headers, "GET");

export const makeDelete = (url: string, body: any, headers: any) => request(url, body, headers, "DELETE");

export function login(email: string, password: string) {
    return makePost(API_URL.LOGIN(), { email, password }, {});
}

export function getLoggedInUser() {
    return makeGet(API_URL.GET_LOGGED_IN_USER(), {});
}

export function logout() {
    clearAuthToken();
    window.location.reload();
}
