import React from "react";
import Paper from "@material-ui/core/Paper";
import { ArgumentAxis, ValueAxis, Chart, LineSeries } from "@devexpress/dx-react-chart-material-ui";
import { ValueScale } from "@devexpress/dx-react-chart";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { labelComponent } from "./chartsConfigs";

const styles = () =>
    createStyles({
        root: {
            flex: 1,
            height: "100%",
            minWidth: 320
        }
    });

const UsersGrowth = ({
    classes,
    chartData,
    chartsWrapperHeight
}: WithStyles<typeof styles> & { chartData: any[]; chartsWrapperHeight: number }) => {
    return (
        <Paper className={classes.root}>
            <div style={{ margin: 10 }}>
                <Chart height={chartsWrapperHeight} data={chartData}>
                    <ValueScale name="total" />
                    <ArgumentAxis labelComponent={labelComponent} showGrid />
                    <ValueAxis scaleName="total" showGrid showLine showTicks />
                    <LineSeries name="Total Transactions" valueField="total" argumentField="month" scaleName="total" />
                </Chart>
            </div>
        </Paper>
    );
};

export default withStyles(styles)(UsersGrowth);
