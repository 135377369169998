import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import { useFormInput } from "../../utils/hooks";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextareaAutosize,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import { v4 } from "uuid";
import { MQTT_ENV } from "../../constants/app";
const MessagingDialog = ({
    isOpen,
    handleClose,
    sendMessage,
    fullScreen,
}: {
    isOpen: boolean;
    handleClose: () => void;
    sendMessage: (topic: string, message: string) => void;
    fullScreen?: boolean;
}) => {
    const [error, setError] = useState("");
    const { value: deviceId, onChange: onDeviceIdChange } = useFormInput("", () => setError(""));
    const { value: type, onChange: onTypeChange } = useFormInput<
        "ADD_PACKAGES" | "REMOVE_PACKAGES" | "PING" | "SELF_UPDATE" | "START_TUNNEL" | "CUSTOM" | ""
    >("", () => setError(""));
    const { value: customType, onChange: onCustomTypeChange } = useFormInput("", () => setError(""));
    const { value: payload, onChange: onPayloadChange } = useFormInput("", () => setError(""));
    const [correlationId, onCorrelationIdChange] = useState(false);

    const validateDate = () => {
        if (type === "REMOVE_PACKAGES" || type === "ADD_PACKAGES") {
            try {
                const pl: Array<{ id: string; uri: string }> = JSON.parse(payload);
                if (!Array.isArray(pl)) {
                    setError("Payload should be array");
                    return false;
                }
                if (pl.length === 0) {
                    setError("Payload should contain at last one item");
                    return false;
                }
                for (let i = 0; i < pl.length; i++) {
                    const item = pl[i];
                    if (!item.uri) {
                        setError("Uri is required");
                        return false;
                    }
                }
            } catch (e) {
                setError("Invalid json");
                return false;
            }
        }
        if (type === "PING" || type === "SELF_UPDATE") {
            return true;
        }
        if (type === "START_TUNNEL") {
            try {
                const tp = JSON.parse(payload);
                const list = ["tunnelId", "address", "devicePort", "clientPort", "localPort"];
                for (const l of list) {
                    if (!tp[l]) {
                        setError(`${l} is required`);
                        return false;
                    }
                }
            } catch (error) {
                setError("Invalid json");
                return false;
            }
        }
        setError("");
        return true;
    };
    const sendData = () => {
        const data: any = {};
        const topic = `${MQTT_ENV}/device--${deviceId}/server`;
        const valid = validateDate();
        if (!valid) {
            return;
        }
        if (correlationId) {
            data.correlationId = v4();
        }
        if (type === "CUSTOM") {
            data.type = customType;
        } else {
            data.type = type;
        }
        if (payload) {
            data.payload = JSON.parse(payload);
        }
        sendMessage(topic, JSON.stringify(data));
    };
    return (
        <Dialog fullScreen={fullScreen} open={isOpen} onClose={handleClose} style={{}}>
            <DialogTitle id="add-role">Send Message To Device</DialogTitle>
            <DialogContent>
                {!!error && <FormHelperText error>{error}</FormHelperText>}
                <div style={{ display: "flex", flexDirection: "column", width: 500 }}>
                    <TextField value={deviceId} label="Device Id" onChange={onDeviceIdChange} margin="normal" />
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Message Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            onChange={onTypeChange}
                        >
                            <MenuItem value="ADD_PACKAGES">ADD_PACKAGES</MenuItem>
                            <MenuItem value="REMOVE_PACKAGES">REMOVE_PACKAGES</MenuItem>
                            <MenuItem value="PING">PING</MenuItem>
                            <MenuItem value="SELF_UPDATE">SELF_UPDATE</MenuItem>
                            <MenuItem value="START_TUNNEL">START_TUNNEL</MenuItem>
                            <MenuItem value="CUSTOM">CUSTOM</MenuItem>
                        </Select>
                    </FormControl>
                    {type === "CUSTOM" && (
                        <TextField value={customType} label="Type" onChange={onCustomTypeChange} margin="normal" />
                    )}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={correlationId}
                                onChange={(a, b) => onCorrelationIdChange(b)}
                                name="checkedF"
                            />
                        }
                        label="Generate Correlation Id"
                    />

                    <FormControl style={{ marginTop: 10 }}>
                        <TextareaAutosize
                            minLength={200}
                            value={payload}
                            onChange={onPayloadChange}
                            rowsMin={15}
                            placeholder="Payload"
                            id="simple-select-label"
                        ></TextareaAutosize>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={validateDate} color="primary">
                    Validate
                </Button>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={sendData} color="primary" autoFocus>
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MessagingDialog;
