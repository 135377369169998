import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
    Email as EmailIcon,
    Message as MessageIcon,
    Settings as SettingsIcon,
    Looks as EngineIcon,
    VerifiedUser as EmailDomainIcon,
    Group as UsersIcon,
    VpnKey as KeyIcon,
    AccessibilityNew as AccessibilityIcon,
    Book as BookIcon,
    DeviceHub as DeviceHubIcon,
    SentimentVerySatisfiedOutlined as SentimentVerySatisfiedOutlinedIcon,
} from "@material-ui/icons";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Theme, WithStyles } from "@material-ui/core";
import { ROUTES_MAP } from "../../constants/routes";
import { logout } from "../../api";

const styles = ({ palette }: Theme) =>
    createStyles({
        toolbar: {
            height: 72,
            minHeight: 72,
            backgroundColor: palette.primary.main,
            color: "white",
        },
        emptySpace: {
            flex: 1,
        },
        menuIcon: {
            color: "#fff",
        },
        title: {
            fontSize: 32,
            minWidth: 200,
        },
        titleIcon: {
            fontSize: 20,
        },
    });

type AppHeaderProps = WithStyles<typeof styles>;

const AppHeader = ({ classes }: AppHeaderProps) => {
    const history = useHistory();
    const location = useLocation();
    const initialRouteIndex = ROUTES_MAP.indexOf(location.pathname) === -1 ? 0 : ROUTES_MAP.indexOf(location.pathname);
    const [value, setValue] = useState(initialRouteIndex);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
        history.replace(ROUTES_MAP[value]);
        setValue(value);
    };

    const handleMenuClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        logout();
    };

    return (
        <Toolbar color="primary" className={classes.toolbar}>
            <Typography color="inherit" className={classes.title}>
                Administrat
                <SettingsIcon className={classes.titleIcon} />r
            </Typography>
            <Tabs
                indicatorColor="secondary"
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
            >
                <Tab icon={<MessageIcon />} label="Message Explorer" />
                <Tab icon={<EmailIcon />} label="Emails" />
                <Tab icon={<EmailDomainIcon />} label="Email domains" />
                <Tab icon={<EngineIcon />} label="Tunnel Engines" />
                <Tab icon={<UsersIcon />} label="Users" />
                <Tab icon={<KeyIcon />} label="Authorities" />
                <Tab icon={<AccessibilityIcon />} label="Roles" />
                <Tab icon={<BookIcon />} label="Packages" />
                <Tab icon={<SentimentVerySatisfiedOutlinedIcon />} label="Client Version" />
                <Tab icon={<BookIcon />} label="Organization" />
                <Tab icon={<DeviceHubIcon />} label="Device Packages" />
                <Tab icon={<DeviceHubIcon />} label="Coupon Code" />
            </Tabs>
            <div className={classes.emptySpace} />
            <IconButton
                aria-label="More"
                aria-owns={anchorEl ? "admin-menu" : ""}
                aria-haspopup="true"
                onClick={handleMenuClick}
                className={classes.menuIcon}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu id="admin-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleLogout}>
                    <div>Logout</div>
                </MenuItem>
            </Menu>
        </Toolbar>
    );
};

export default withStyles(styles)(AppHeader);
