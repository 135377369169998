import React, { useContext, useEffect, useState } from "react";
import RolesTable from "./rolesTable";
import { addRole, deletedRoles, getRoles, updateRole } from "../../api/roles";
import { getRolesAllMethods } from "../../api/methods";
import { IRole } from "../../interfaces/roles";
import { IMethod } from "../../interfaces/authorities";
import { ServiceMethodsContext } from "../../context/role";
import { AppDispatch } from "../../context";
import { getOrganizations } from "../../api/organizations";
import { IOrganization } from "../../interfaces";

const Roles = () => {
    const [tableData, setTableData] = useState<IRole[]>([]);
    const [allMethods, setAllMethods] = useState<IMethod[]>([]);
    const [organizations, setOrganizations] = useState<IOrganization[]>([]);
    const dispatch = useContext(AppDispatch)!;

    function doAddRole(data: any) {
        return new Promise((resolve, reject) => {
            addRole(data)
                .then((response: any) => {
                    updateTableData(response && response.data, "add");
                    resolve(response && response.data);
                })
                .catch((e: any) => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                    reject(e);
                });
        });
    }

    function doDeleteRoles(data: any) {
        return new Promise((resolve, reject) => {
            deletedRoles(data)
                .then((response: any) => {
                    updateTableData(data, "delete");
                    resolve(response && response.data);
                })
                .catch((e: any) => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                    reject(e);
                });
        });
    }

    function updateTableData(data: any, action: string) {
        let newTableData = [...tableData];

        if (action === "delete" && Array.isArray(data)) {
            newTableData = newTableData.filter((role) => data.indexOf(role.id) === -1);
        } else {
            const roleForTable: IRole = data && {
                id: data._id,
                name: data.name,
                serviceName: data.serviceName,
                authorities: data.authorities,
                access: data.access,
                uiConfig: data.uiConfig,
                allOrganization: data.allOrganization,
                organizationIds: data.organizationIds || [],
            };
            if (action === "update") {
                const index = tableData.indexOf(tableData.filter((td) => td.id === roleForTable.id)[0]);
                newTableData.splice(index, 1);
            }
            newTableData.push(roleForTable);
        }

        setTableData(newTableData);
    }

    const doUpdateRole = (roleId: string, data: any) => {
        updateRole(roleId, data)
            .then((response: any) => updateTableData(response && response.data, "update"))
            .catch((e: any) => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };

    useEffect(() => {
        getOrganizations({ filters: [{ property: "whiteLabel", value: "all" }] })
            .then((response) => {
                const data = (response && response.data) || [];
                setOrganizations(data);
            })
            .catch((e) => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    }, []);

    useEffect(() => {
        getRoles()
            .then((response) => {
                const roles = (response && response.data) || [];
                const tableData: IRole[] = roles.map((r: any) => {
                    const rData: IRole = {
                        id: r._id,
                        name: r.name,
                        serviceName: r.serviceName,
                        authorities: r.authorities,
                        access: r.access,
                        uiConfig: r.uiConfig,
                        allOrganization: r.allOrganization,
                        organizationIds: r.organizationIds || [],
                    };
                    return rData;
                });
                setTableData(tableData);
            })
            .catch((e: any) => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
        getRolesAllMethods()
            .then((methods) => {
                setAllMethods(methods && methods.data);
            })
            .catch((e: any) => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    }, []);
    return (
        <ServiceMethodsContext.Provider value={allMethods}>
            <RolesTable
                tableData={tableData}
                updateRole={doUpdateRole}
                addRole={doAddRole}
                deleteRoles={doDeleteRoles}
                allOrganizations={organizations}
            />
        </ServiceMethodsContext.Provider>
    );
};

export default Roles;
