import React, { useState, useEffect, useContext } from "react";
import { WithStyles, withStyles, Tooltip, Theme, FormControl } from "@material-ui/core";
import { createStyles, IconButton } from "@material-ui/core";
import { IPackage } from "../../interfaces";
import Grid, { IGridColumn } from "../grid";
import { TableCellProps } from "react-virtualized";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EnableIcon from "@material-ui/icons/StarOutlined";
import {
    getPackages,
    addPackage,
    activatePackage,
    uploadPackage,
    updatePackage,
    deletePackage,
} from "../../api/package";
import { AppDispatch } from "../../context";
import AddPackageDialog from "./addPackageDialog";
import AddPackageFilterDialog from "./addPackageFilterDialog";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flex: 1,
            height: "calc(100vh - 72px)",
            flexDirection: "column",
        },
        card: {
            width: 300,
            margin: "auto",
        },
        gridDefaultContextRenderer: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        textFieldsWrapper: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
        },
        nextButton: {
            float: "right",
        },
        selectFormControl: {
            margin: "8px 0",
        },
        gridWrapper: {
            minHeight: 300,
            flex: 4,
        },
        devicesAndTunnelsWrapper: {
            minWidth: 55,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",
        },
        eyeButton: {
            marginLeft: 10,
            padding: 8,
            [theme.breakpoints.down("sm")]: {
                padding: 2,
            },
        },
    });

const Packages = ({ classes }: WithStyles<typeof styles>) => {
    const dispatch = useContext(AppDispatch)!;
    const [packages, setPackages] = useState<IPackage[]>([]);
    const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
    const [packageInfo, setPackageInfo] = useState<{
        name?: string;
        version?: string;
        description?: string;
        _id?: string;
    }>({});
    const [isPackageFilterDialogOpen, setIsPackageFilterDialogOpen] = useState<boolean>(false);
    const [packageId, setPackageId] = useState<string>("");
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const reload = () =>
        getPackages()
            .then((response) => {
                setIsDataLoading(false);
                const data = (response && response.data) || [];
                setPackages(data);
            })
            .catch((e) => {
                setIsDataLoading(false);
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    useEffect(() => {
        setIsDataLoading(true);
        reload();
    }, []);

    const onDeletePackage = async (id: string) => {
        try {
            await deletePackage(id);
        } catch {}
        reload();
    };
    const gridDefaultContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        let data = <div className={classes.gridDefaultContextRenderer}>{JSON.stringify(props.cellData)}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={props.cellData} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };
    const onViewFiltersClick = (props: TableCellProps) => {};
    const devicesCellRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => (
        <div className={classes.devicesAndTunnelsWrapper}>
            <div>{props.cellData && props.cellData.length}</div>
            <IconButton className={classes.eyeButton} onClick={() => onViewFiltersClick(props)}>
                <VisibilityIcon />
            </IconButton>
        </div>
    );
    const editCellRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => (
        <IconButton
            onClick={() => {
                setPackageInfo(props.rowData);
                setIsAddDialogOpen(true);
            }}
        >
            <EditIcon />
        </IconButton>
    );
    const addCellRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => (
        <IconButton
            onClick={() => {
                setPackageId(props.rowData._id);
                setIsPackageFilterDialogOpen(true);
            }}
        >
            <AddIcon />
        </IconButton>
    );
    const deleteCellRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => (
        <IconButton
            onClick={() => {
                onDeletePackage(props.rowData._id);
            }}
        >
            <DeleteIcon />
        </IconButton>
    );
    const activateCellRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => (
        <IconButton onClick={() => activatePackage(props.rowData._id)}>
            <EnableIcon />
        </IconButton>
    );
    const handelPackageFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        debugger;
        const data: FormData = new FormData();
        for (let index = 0; index < event.target.files!.length; index++) {
            const element = event.target.files![index];
            data.append("package", element as any, element.name);
        }

        try {
            await uploadPackage(data);
            reload();
        } catch (e) {
            dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
        }
    };
    const columns: IGridColumn[] = [
        {
            dataKey: "_id",
            width: 200,
            label: "ID",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "name",
            width: 200,
            label: "Name",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "description",
            width: 200,
            label: "Description",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "version",
            width: 200,
            label: "Version",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "status",
            width: 200,
            label: "Status",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "runStrategy",
            width: 200,
            label: "runStrategy",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "filters",
            width: 100,
            label: "Filters",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: devicesCellRenderer,
        },
        { dataKey: "", width: 50, label: "Edit", flexGrow: 1, flexShrink: 1, cellContentRenderer: editCellRenderer },
        {
            dataKey: "",
            width: 50,
            label: "Add Filter",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: addCellRenderer,
        },
        {
            dataKey: "Activate",
            width: 50,
            label: "Activate",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: activateCellRenderer,
        },
        {
            dataKey: "Delete",
            width: 50,
            label: "Delete",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: deleteCellRenderer,
        },
    ];

    const createPackage = async ({
        name,
        description,
        version,
        _id,
        accessPermissions,
        postInstall,
        preDelete,
    }: Partial<IPackage>) => {
        setIsDataLoading(true);
        if (!_id) {
            try {
                await addPackage({
                    accessPermissions,
                    preDelete,
                    postInstall,
                    name,
                    description,
                    version,
                });
            } catch (e) {}
        } else {
            try {
                await updatePackage(_id, {
                    accessPermissions,
                    preDelete,
                    postInstall,
                    name,
                    description,
                    version,
                });
            } catch (e) {}
        }
        setIsDataLoading(false);
        setIsAddDialogOpen(false);
        getPackages()
            .then((response) => {
                setIsDataLoading(false);
                const data = (response && response.data) || [];
                setPackages(data);
            })
            .catch((e) => {
                setIsDataLoading(false);
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };
    const onAddDialogClose = () => {
        setIsAddDialogOpen(false);
    };
    const onPackageFilterDialogClose = () => {
        setIsPackageFilterDialogOpen(false);
    };
    // there are error in @types IconButton do not accept  component props but it exist in parent class
    // @see https://material-ui.com/api/button-base/
    const IconButtonWrapper: any = IconButton;
    const id = `upload-device-package-${Math.floor(Math.random() * 10000).toString()}`;
    return (
        <div className={classes.root}>
            <div style={{ margin: "auto" }}>
                <IconButton
                    onClick={() => {
                        setPackageInfo({});
                        setIsAddDialogOpen(true);
                    }}
                >
                    Add Package
                    <AddIcon />
                </IconButton>
                <FormControl>
                    <input
                        id={id}
                        multiple
                        type="file"
                        style={{ display: "none" }}
                        onChange={handelPackageFileChange}
                    />
                    <label htmlFor={id}>
                        <IconButtonWrapper component="span">
                            Upload Package <CloudUploadIcon />
                        </IconButtonWrapper>
                    </label>
                </FormControl>
            </div>
            <div className={classes.gridWrapper}>
                <Grid
                    data={packages}
                    columns={columns}
                    enablePagination
                    gridToolbarTitle="Packages"
                    searchValueChangeTimeOutForCall={1000}
                    isDataLoading={isDataLoading}
                    rowsPerPageOptions={[100, 250, 500]}
                    labelRowsPerPage={window.innerWidth > 992 ? undefined : null}
                />
                <AddPackageDialog
                    isOpen={isAddDialogOpen}
                    onClose={onAddDialogClose}
                    packageInfo={packageInfo}
                    onSave={createPackage}
                />
                <AddPackageFilterDialog
                    isOpen={isPackageFilterDialogOpen}
                    onClose={onPackageFilterDialogClose}
                    packageId={packageId}
                    onSave={() => {
                        setIsPackageFilterDialogOpen(false);
                        reload();
                    }}
                />
            </div>
        </div>
    );
};

export default withStyles(styles)(Packages);
