import React, { useEffect, useRef } from "react";
import "./tree.css";
import withStyles, { ClassNameMap, StyleRules } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import d3 from "d3";

import { ITreeData } from "../../interfaces/d3tree";
import { MqttClient } from "mqtt";

const styles: StyleRules<string> = createStyles({
    tree: {
        minHeight: 474,
        // minWidth: 1000
    },
});

const margin: any = { top: 20, right: 120, bottom: 20, left: 120 };
const width: number = 960 - margin.right - margin.left;
const height: number = 470 - margin.top - margin.bottom;

let globalId = 0;
const duration = 750;
let timerId: any = null;

const Tree = ({
    classes,
    updateMessages,
    getNodeVisibility,
    visibilityFilters,
    client,
}: {
    classes: Partial<ClassNameMap<string>>;
    updateMessages: (messages: string[]) => void;
    getNodeVisibility: (topic: string, message: string) => boolean;
    visibilityFilters: {
        searchByTopic: string;
        searchByMessageType: string;
    };
    client: MqttClient | null;
}) => {
    const handlerRef = useRef<Function | null>(null);
    const visualizationRef = useRef<HTMLDivElement | null>(null);
    const svgRef = useRef<any>(null);
    const treeData = useRef<ITreeData>({
        name: "Messages",
        children: [],
        ownMessagesCount: 0,
        allMessagesCount: 0,
        messages: [],
        validMessages: true,
    });

    const root: ITreeData | null = treeData.current;
    root.x0 = height / 2;
    root.y0 = 0;
    const tree: any = d3.layout.tree().size([height, width]);
    const diagonal: any = d3.svg.diagonal().projection(function (d) {
        return [d.y, d.x];
    });
    let svg = svgRef.current;
    // eslint-disable-next-line
    d3.select(self.frameElement).style("height", "470px");

    useEffect(() => {
        handlerRef.current = addTopicToTree;
    }, [addTopicToTree]);

    useEffect(() => {
        if (!client) {
            return;
        }
        client.on("message", function (topic, payload) {
            const topicParts = topic.split("/");
            const message = payload.toString();
            handlerRef.current!(treeData.current, topicParts, message);
        });
    }, [client]);

    useEffect(() => {
        svg = d3
            .select("#visualization")
            .append("svg")
            .attr("width", width + margin.right + margin.left)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        update(root);
        svgRef.current = svg;
    }, [root]);

    useEffect(() => {
        bufferedUpdate();
    }, [visibilityFilters]);

    function update(source: any) {
        // Compute the new tree layout.
        const nodes = tree.nodes(root).reverse(),
            links = tree.links(nodes);

        // Normalize for fixed-depth.
        nodes.forEach(function (d: any) {
            d.y = d.depth * 180;
        });

        // Update the nodes…
        const node = svg.selectAll("g.node").data(nodes, function (d: any) {
            return d.id || (d.id = ++globalId);
        });

        // Enter any new nodes at the parent's previous position.
        const nodeEnter = node
            .enter()
            .append("g")
            .attr("class", "node")
            .attr("transform", function (d: any) {
                return "translate(" + source.y0 + "," + source.x0 + ")";
            })
            .on("click", click);

        nodeEnter
            .append("circle")
            .attr("r", 1e-6)
            .style("fill", function (d: any) {
                return d._children ? "lightsteelblue" : "#fff";
            });

        nodeEnter
            .append("text")
            .attr("x", function (d: any) {
                return d.children || d._children ? -13 : 13;
            })
            .attr("dy", ".35em")
            .attr("text-anchor", function (d: any) {
                return d.children || d._children ? "end" : "start";
            })
            .text(function (d: any) {
                let name = d.topic;
                if (d.name) {
                    name = d.name;
                }
                return name + " (" + d.allMessagesCount + "," + d.ownMessagesCount + ")";
            })
            .style("fill-opacity", 1e-6);

        // Transition nodes to their new position.
        const nodeUpdate = node
            .transition()
            .duration(duration)
            .attr("transform", function (d: any) {
                return "translate(" + d.y + "," + d.x + ")";
            });

        nodeUpdate
            .select("circle")
            .attr("r", 10)
            .attr("class", function (d: any) {
                if (d.ownMessagesCount === 0) {
                    return "";
                }
                return d.validMessages ? "circle-valid" : "circle-invalid";
            })
            .style("fill", function (d: any) {
                return d._children ? "lightsteelblue" : "#fff";
            });

        nodeUpdate
            .select("text")
            .text(function (d: any) {
                let name = d.topic;
                if (d.name) {
                    name = d.name;
                }
                return name + " (" + d.allMessagesCount + "," + d.ownMessagesCount + ")";
            })
            .style("fill-opacity", 1);

        // Transition exiting nodes to the parent's new position.
        const nodeExit = node
            .exit()
            .transition()
            .duration(duration)
            .attr("transform", function (d: any) {
                return "translate(" + source.y + "," + source.x + ")";
            })
            .remove();

        nodeExit.select("circle").attr("r", 1e-6);

        nodeExit.select("text").style("fill-opacity", 1e-6);

        // Update the links…
        const link = svg.selectAll("path.link").data(links, function (d: any) {
            return d.target.id;
        });

        // Enter any new links at the parent's previous position.
        link.enter()
            .insert("path", "g")
            .attr("class", "link")
            .attr("d", function (d: any) {
                const o = { x: source.x0, y: source.y0 };
                return diagonal({ source: o, target: o });
            });

        // Transition links to their new position.
        link.transition().duration(duration).attr("d", diagonal);

        // Transition exiting nodes to the parent's new position.
        link.exit()
            .transition()
            .duration(duration)
            .attr("d", function (d: any) {
                const o = { x: source.x, y: source.y };
                return diagonal({ source: o, target: o });
            })
            .remove();

        // Stash the old positions for transition.
        nodes.forEach(function (d: any) {
            d.x0 = d.x;
            d.y0 = d.y;
        });
    }

    function bufferedUpdate() {
        if (timerId) {
            clearTimeout(timerId);
            timerId = null;
        }
        timerId = setTimeout(function () {
            timerId = null;
            update(treeData.current);
        }, 300);
    }

    function validateMessage(message: any) {
        try {
            const possibleKeys = ["type", "payload", "error", "correlationId"];
            const msgObj = JSON.parse(message);
            for (const i in msgObj) {
                if (possibleKeys.indexOf(i) === -1) {
                    return false;
                }
            }
        } catch (e) {
            return false;
        }
        return true;
    }

    function addTopicToTree(currentNode: any, topicParts: any, payload: any) {
        // TODO update tree data correctly
        if (topicParts.length === 3 && !getNodeVisibility(topicParts.join("/"), payload)) {
            return;
        }
        currentNode.allMessagesCount++;
        if (topicParts.length === 0) {
            currentNode.ownMessagesCount++;
            currentNode.validMessages = currentNode.validMessages && validateMessage(payload);
            currentNode.messages.push(payload);

            bufferedUpdate();
            return;
        }
        const currenTopicPart = topicParts[0];
        if (!currentNode.hasOwnProperty("children")) {
            currentNode.children = [];
        }
        // search for existing topic
        for (let i = 0; i < currentNode.children.length; i++) {
            if (currentNode.children[i].topic === topicParts[0]) {
                topicParts.shift();
                addTopicToTree(currentNode.children[i], topicParts, payload);
                return;
            }
        }

        const length = currentNode.children.push({
            topic: topicParts[0],
            ownMessagesCount: 0,
            allMessagesCount: 0,
            messages: [],
            validMessages: true,
        });
        topicParts.shift();
        addTopicToTree(currentNode.children[length - 1], topicParts, payload);
    }

    // show messages on click.
    function click(d: any) {
        clearHighligh();
        checkMessages(d.messages);
        checkTopic(d);
        updateMessages(d.messages);
    }

    function clearHighligh() {
        const nodes = d3.selectAll(".circle-highlighted");
        if (Array.isArray(nodes) && Array.isArray(nodes[0])) {
            nodes[0].forEach(function (n: any) {
                n.classList.remove("circle-highlighted");
            });
        }
    }

    function checkMessages(messages: string[]) {
        messages.forEach(function (message: any) {
            let correlationId = "";
            try {
                const msgObj: any = JSON.parse(message);
                correlationId = msgObj.correlationId;
            } catch (e) {}
            if (correlationId) {
                processNodesByCorrelationId(correlationId);
            }
        });
    }

    function checkTopic(node: any) {
        if (node.topic && checkPattern(node)) {
            processNodesByTopic(node.topic);
        }
    }

    function processNodesByTopic(topic: string) {
        const node = d3.selectAll("circle").filter(function (d) {
            let correlationId = "";
            if (d.messages && d.messages[0]) {
                try {
                    const msgObj: any = JSON.parse(d.messages[0]);
                    correlationId = msgObj.correlationId;
                } catch (e) {}
            }
            return correlationId === topic;
        });
        if (Array.isArray(node) && Array.isArray(node[0])) {
            node[0].forEach(function (n: any) {
                highlightNode(n);
            });
        }
    }

    function processNodesByCorrelationId(correlationId: string) {
        const node = d3.selectAll("circle").filter(function (d) {
            return d.topic === correlationId && checkPattern(d);
        });
        if (Array.isArray(node) && Array.isArray(node[0])) {
            node[0].forEach(function (n: any) {
                highlightNode(n);
            });
        }
    }

    function checkPattern(node: any) {
        if (node.messages && node.messages[0]) {
            try {
                const msgObj: any = JSON.parse(node.messages[0]);
                return !(msgObj.to && msgObj.from);
            } catch (e) {
                return true;
            }
        }
        return true;
    }

    function highlightNode(node: any) {
        node.classList.add("circle-highlighted");
    }

    return (
        <div className={classes.tree}>
            <div id={"visualization"} ref={visualizationRef} className={classes.visualization} />
        </div>
    );
};

export default withStyles(styles)(Tree);
