import React, { useContext, useEffect, useState } from "react";
import RolesTableHead from "./rolesTableHead";
import RolesTableToolbar from "./rolesTableToolbar";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import createStyles from "@material-ui/core/styles/createStyles";
import { IRole } from "../../interfaces/roles";
import DeleteRoleDialog from "./deleteRoleDialog";
import { AppDispatch } from "../../context";
import { Select, MenuItem, ListItemText, FormControlLabel, IconButton } from "@material-ui/core";
import { IOrganization } from "../../interfaces";
import MultiSelectDialog from "../shared/multiSelectDialog";
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import { ServiceMethodsContext } from "../../context/role";
import { IMethod } from "../../interfaces/authorities";

const styles = () =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flex: 1,
        },
        table: {
            minWidth: 1020,
        },
        tableWrapper: {
            overflowX: "auto",
            flex: 1,
        },
        authoritiesSelectSearchField: {
            margin: "8px 28px",
            width: "80%",
        },
    });

// TODO should be removed
export const allConfigs: string[] = [
    "*",
    "delete_tunnel_action",
    "edit_tunnel_action",
    "routes_devices",
    "routes_tunnels",
    "routes_changedeviceowner",
    "routes_users",
    "routes_usage",
    "add_tunnel_action",
];
const RolesTable = ({
    classes,
    tableData,
    updateRole,
    deleteRoles,
    addRole,
    allOrganizations,
}: WithStyles<typeof styles> & {
    tableData: IRole[];
    updateRole: (roleId: string, data: any) => void;
    addRole: (data: any) => Promise<any>;
    deleteRoles: (data: any) => Promise<any>;
    allOrganizations: IOrganization[];
}) => {
    const [order, setOrder] = useState<"asc" | "desc">("asc");
    const [orderBy, setOrderBy] = useState<string>("name");
    const [selected, setSelected] = useState<string[]>([]);
    const [data, setData] = useState<IRole[]>([]);
    const [openAuthoritiesMultiSelect, setOpenAuthoritiesMultiSelect] = useState<{ [key: string]: boolean }>({});
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [deleteRoleError, setDeleteRoleError] = useState<string>("");
    const [allUiConfigs, setAllUiConfigs] = useState<{ [key: string]: { includes?: string[]; excludes?: string[] } }>(
        {}
    );
    const [includesEditingRoleId, setIncludesEditingRoleId] = useState<string>("");
    const [excludesEditingRoleId, setExcludesEditingRoleId] = useState<string>("");
    const [mapOrgIdsFromRoles, setMapOrgIdsFromRoles] = useState<{
        [key: string]: string[];
    }>({});
    const [mapAuthoritiesFromRoles, setMapAuthoritiesFromRoles] = useState<{
        [key: string]: string[];
    }>({});

    const [openOrgsMultiSelect, setOpenOrgsMultiSelect] = useState<{ [key: string]: boolean }>({});

    const dispatch = useContext(AppDispatch)!;
    const allMethods = useContext(ServiceMethodsContext);

    useEffect(() => {
        const initialAllUiConfigs: { [key: string]: { includes?: string[]; excludes?: string[] } } = {};
        const data: IRole[] = [];
        const mapOrgIdsFromRolesData: {
            [key: string]: string[];
        } = {};
        const openOrgsMultiSelectData: {
            [key: string]: boolean;
        } = {};
        const openAuthoritiesMultiSelectData: {
            [key: string]: boolean;
        } = {};
        const mapAuthoritiesFromRolesData: {
            [key: string]: string[];
        } = {};
        for (let role of tableData) {
            const item: IRole = {
                id: role.id,
                name: role.name,
                authorities: Array.isArray(role.authorities) ? [...role.authorities] : [],
                access: role.access,
                uiConfig: role.uiConfig,
                allOrganization: role.allOrganization,
                organizationIds: role.organizationIds || [],
            };
            initialAllUiConfigs[role.id] = {};
            initialAllUiConfigs[role.id].includes = role.uiConfig ? role.uiConfig!.includes || [] : [];
            initialAllUiConfigs[role.id].excludes = role.uiConfig ? role.uiConfig!.excludes || [] : [];
            mapOrgIdsFromRolesData[role.id] = role.organizationIds || [];
            mapAuthoritiesFromRolesData[role.id] = role.authorities || [];
            openOrgsMultiSelectData[role.id] = false;
            openAuthoritiesMultiSelectData[role.id] = false;
            data.push(item);
        }
        setMapAuthoritiesFromRoles(mapAuthoritiesFromRolesData);
        setOpenAuthoritiesMultiSelect(openAuthoritiesMultiSelectData);
        setAllUiConfigs(initialAllUiConfigs);
        setMapOrgIdsFromRoles(mapOrgIdsFromRolesData);
        setOpenOrgsMultiSelect(openOrgsMultiSelectData);
        setData(data);
    }, [tableData]);

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelected(data.map((role) => role.id));
            return;
        }
        setSelected([]);
    };
    const handleRequestSort = (event: React.MouseEvent<HTMLElement>, property: string) => {
        const orderBy = property;
        let o: "asc" | "desc" = "desc";

        if (orderBy === property && order === "desc") {
            o = "asc";
        }
        setOrder(o);
        setOrderBy(orderBy);
    };
    function desc(a: any, b: any, orderBy: string) {
        if (b[orderBy] === null || b[orderBy] === undefined) {
            b[orderBy] = "";
        }
        if (a[orderBy] === null || a[orderBy] === undefined) {
            a[orderBy] = "";
        }
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    function stableSort(array: any[], cmp: any) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a: any, b: any) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    function getSorting(order: "asc" | "desc", orderBy: string) {
        return order === "desc" ? (a: any, b: any) => desc(a, b, orderBy) : (a: any, b: any) => -desc(a, b, orderBy);
    }
    const onSelectRoleClick = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };
    const shouldUpdate = (current: any, previous: any) => {
        if (!Array.isArray(current) || !Array.isArray(previous)) {
            return false;
        }
        if (current.length !== previous.length) {
            return true;
        }
        for (let i = 0; i < current.length; i += 1) {
            if (previous.indexOf(current[i]) === -1) {
                return true;
            }
        }

        return false;
    };
    const onDeleteRolesClick = () => {
        setOpenDeleteDialog(true);
    };
    const closeDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const doDeleteRoles = () => {
        deleteRoles(selected)
            .then(() => {
                setSelected([]);
                setOpenDeleteDialog(false);
            })
            .catch((e) => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                setDeleteRoleError("Something went wrong");
            });
    };

    const handleIncludesChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>, roleId: string) => {
        const newAllUiConfigs = { ...allUiConfigs };
        newAllUiConfigs[roleId].includes = e.target.value as string[];
        setAllUiConfigs(newAllUiConfigs);
    };
    const handleExcludesChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>, roleId: string) => {
        const newAllUiConfigs = { ...allUiConfigs };
        newAllUiConfigs[roleId].excludes = e.target.value as string[];
        setAllUiConfigs(newAllUiConfigs);
    };
    const handleIncludesSelectOpen = (roleId: string) => setIncludesEditingRoleId(roleId);
    const handleIncludesSelectClose = (e: React.ChangeEvent<{}>, roleId: string) => {
        const current = allUiConfigs[roleId].includes;
        const r = data.filter((r: IRole) => r.id === roleId)[0];
        const previous = r.uiConfig ? r.uiConfig.includes : [];
        if (shouldUpdate(current, previous)) {
            updateRole(roleId, { uiConfig: allUiConfigs[roleId] });
        }
        setIncludesEditingRoleId("");
    };
    const handleExcludesSelectOpen = (roleId: string) => setExcludesEditingRoleId(roleId);
    const handleExcludesSelectClose = (e: React.ChangeEvent<{}>, roleId: string) => {
        const current = allUiConfigs[roleId].excludes;
        const r = data.filter((r: IRole) => r.id === roleId)[0];
        const previous = r.uiConfig ? r.uiConfig.excludes : [];
        if (shouldUpdate(current, previous)) {
            updateRole(roleId, { uiConfig: allUiConfigs[roleId] });
        }
        setExcludesEditingRoleId("");
    };

    return (
        <>
            <Paper className={classes.root}>
                <RolesTableToolbar
                    allOrganizations={allOrganizations}
                    numSelected={selected.length}
                    addRole={addRole}
                    deleteRoles={onDeleteRolesClick}
                />
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <RolesTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data.length}
                        />
                        <TableBody>
                            {stableSort(data, getSorting(order, orderBy)).map((role: IRole) => {
                                const isSelected = selected.indexOf(role.id) !== -1;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isSelected}
                                        tabIndex={-1}
                                        key={role.id}
                                        selected={isSelected}
                                        style={{ display: "gird" }}
                                    >
                                        <TableCell padding="checkbox" style={{ flex: 1 }}>
                                            <Checkbox
                                                checked={isSelected}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                    onSelectRoleClick(event, role.id)
                                                }
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="default" style={{ flex: 2 }}>
                                            {role.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="default" style={{ flex: 3 }}>
                                            <span>{`${mapAuthoritiesFromRoles[role.id].length} methods allowed`}</span>
                                            <IconButton
                                                onClick={() => {
                                                    const d = { ...openAuthoritiesMultiSelect };
                                                    d[role.id] = true;
                                                    setOpenAuthoritiesMultiSelect(d);
                                                }}
                                            >
                                                <ArrowDownIcon />
                                            </IconButton>
                                            <MultiSelectDialog
                                                title="Methods"
                                                isOpen={openAuthoritiesMultiSelect[role.id]}
                                                handleClose={() => {
                                                    const d = { ...openAuthoritiesMultiSelect };
                                                    d[role.id] = false;

                                                    const oldAuthorities = data.filter((r) => r.id === role.id)[0]
                                                        .authorities;
                                                    const mapAuthoritiesFromRolesData = { ...mapAuthoritiesFromRoles };
                                                    mapAuthoritiesFromRolesData[role.id] = oldAuthorities;
                                                    setMapAuthoritiesFromRoles(mapAuthoritiesFromRolesData);
                                                    setOpenAuthoritiesMultiSelect(d);
                                                }}
                                                handleSave={() => {
                                                    const d = { ...openAuthoritiesMultiSelect };
                                                    d[role.id] = false;
                                                    setOpenAuthoritiesMultiSelect(d);
                                                    updateRole(role.id, {
                                                        authorities: mapAuthoritiesFromRoles[role.id],
                                                    });
                                                }}
                                                data={allMethods}
                                                getIsChecked={(method: IMethod) =>
                                                    mapAuthoritiesFromRoles[role.id].indexOf(method.name) > -1
                                                }
                                                primaryTextLabel="name"
                                                secondaryTextLabel="serviceName"
                                                handleToggle={(method: IMethod) => {
                                                    const currentIndex = mapAuthoritiesFromRoles[role.id].indexOf(
                                                        method.name
                                                    );
                                                    const newData = { ...mapAuthoritiesFromRoles };
                                                    if (currentIndex > -1) {
                                                        newData[role.id].splice(currentIndex, 1);
                                                    } else {
                                                        newData[role.id].push(method.name);
                                                    }
                                                    setMapAuthoritiesFromRoles(newData);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="default" style={{ flex: 2 }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={role.access === "public"}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                            updateRole(role.id, {
                                                                access: e.target.checked ? "public" : "system",
                                                            })
                                                        }
                                                        name="access"
                                                    />
                                                }
                                                label="Public access"
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="default" style={{ flex: 2 }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={role.allOrganization}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                            updateRole(role.id, {
                                                                allOrganization: e.target.checked,
                                                            })
                                                        }
                                                        name="access"
                                                    />
                                                }
                                                label="All organization"
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="default" style={{ flex: 5 }}>
                                            <span>{`${
                                                mapOrgIdsFromRoles[role.id].length
                                            } organizations selected`}</span>
                                            <IconButton
                                                onClick={() => {
                                                    const d = { ...openOrgsMultiSelect };
                                                    d[role.id] = true;
                                                    setOpenOrgsMultiSelect(d);
                                                }}
                                            >
                                                <ArrowDownIcon />
                                            </IconButton>
                                            <MultiSelectDialog
                                                title="Organizations"
                                                isOpen={openOrgsMultiSelect[role.id]}
                                                handleClose={() => {
                                                    const d = { ...openOrgsMultiSelect };
                                                    d[role.id] = false;

                                                    const oldOrgIds = data.filter((r) => r.id === role.id)[0]
                                                        .organizationIds;
                                                    const mapOrgIdsFromRolesData = { ...mapOrgIdsFromRoles };
                                                    mapOrgIdsFromRolesData[role.id] = oldOrgIds;
                                                    setMapOrgIdsFromRoles(mapOrgIdsFromRolesData);
                                                    setOpenOrgsMultiSelect(d);
                                                }}
                                                handleSave={() => {
                                                    const d = { ...openOrgsMultiSelect };
                                                    d[role.id] = false;
                                                    setOpenOrgsMultiSelect(d);
                                                    updateRole(role.id, {
                                                        organizationIds: mapOrgIdsFromRoles[role.id],
                                                    });
                                                }}
                                                data={allOrganizations}
                                                getIsChecked={(org) =>
                                                    mapOrgIdsFromRoles[role.id].indexOf(org._id) > -1
                                                }
                                                primaryTextLabel="name"
                                                secondaryTextLabel="description"
                                                handleToggle={(d: any) => {
                                                    const id: string = d._id;
                                                    const currentIndex = mapOrgIdsFromRoles[role.id].indexOf(id);
                                                    const newData = { ...mapOrgIdsFromRoles };
                                                    if (currentIndex > -1) {
                                                        newData[role.id].splice(currentIndex, 1);
                                                    } else {
                                                        newData[role.id].push(id);
                                                    }
                                                    setMapOrgIdsFromRoles(newData);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row" padding="default" style={{ flex: 5 }}>
                                            <div style={{ display: "flex" }}>
                                                <FormControl style={{ marginRight: 10 }}>
                                                    <Select
                                                        multiple
                                                        value={(allUiConfigs[role.id] || {}).includes || []}
                                                        onChange={(
                                                            e: React.ChangeEvent<{ name?: string; value: unknown }>
                                                        ) => handleIncludesChange(e, role.id)}
                                                        displayEmpty
                                                        renderValue={(selected) => {
                                                            return selected
                                                                ? Array.isArray(selected)
                                                                    ? `${selected.length} configs included`
                                                                    : `1 config included`
                                                                : 0;
                                                        }}
                                                        open={includesEditingRoleId === role.id}
                                                        onOpen={() => handleIncludesSelectOpen(role.id)}
                                                        onClose={(e: React.ChangeEvent<{}>) => {
                                                            handleIncludesSelectClose(e, role.id);
                                                        }}
                                                    >
                                                        {allConfigs.map((config: string, index: number) => (
                                                            <MenuItem key={`${config}-${index}`} value={config}>
                                                                <Checkbox
                                                                    checked={
                                                                        (
                                                                            (allUiConfigs[role.id] || {}).includes || []
                                                                        ).indexOf(config) > -1
                                                                    }
                                                                />
                                                                <ListItemText primary={config} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl>
                                                    <Select
                                                        multiple
                                                        value={(allUiConfigs[role.id] || {}).excludes || []}
                                                        onChange={(
                                                            e: React.ChangeEvent<{ name?: string; value: unknown }>
                                                        ) => handleExcludesChange(e, role.id)}
                                                        displayEmpty
                                                        renderValue={(selected) => {
                                                            return selected
                                                                ? Array.isArray(selected)
                                                                    ? `${selected.length} configs excluded`
                                                                    : `1 config excluded`
                                                                : 0;
                                                        }}
                                                        open={excludesEditingRoleId === role.id}
                                                        onOpen={() => handleExcludesSelectOpen(role.id)}
                                                        onClose={(e: React.ChangeEvent<{}>) => {
                                                            handleExcludesSelectClose(e, role.id);
                                                        }}
                                                    >
                                                        {allConfigs.map((config: string, index: number) => (
                                                            <MenuItem key={`${config}-${index}`} value={config}>
                                                                <Checkbox
                                                                    checked={
                                                                        (
                                                                            (allUiConfigs[role.id] || {}).excludes || []
                                                                        ).indexOf(config) > -1
                                                                    }
                                                                />
                                                                <ListItemText primary={config} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
            {openDeleteDialog && (
                <DeleteRoleDialog
                    isOpen={openDeleteDialog}
                    handleClose={closeDeleteDialog}
                    handleDelete={doDeleteRoles}
                    deleteRoleError={deleteRoleError}
                />
            )}
        </>
    );
};

export default withStyles(styles)(RolesTable);
