import React, { useEffect, useState } from "react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import createStyles from "@material-ui/core/styles/createStyles";
import AuthoritiesTableHead from "./authoritiesTableHead";
import AuthoritiesTableToolbar from "./authoritiesTableToolbar";
import { IMethodWithRoles } from "../../interfaces/authorities";
import { IRole } from "../../interfaces/roles";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";

const styles = () =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flex: 1
        },
        table: {
            minWidth: 1020
        },
        tableWrapper: {
            overflowX: "auto",
            flex: 1
        }
    });

const AuthoritiesTable = ({
    classes,
    tableData,
    allRoles,
    updateRoles
}: WithStyles<typeof styles> & {
    tableData: IMethodWithRoles[];
    allRoles: IRole[];
    updateRoles: (updateData: { rolesToBeRemoved: string[]; rolesToBeAdded: string[] }, authorityName: string) => void;
}) => {
    const [order, setOrder] = useState<"asc" | "desc">("asc");
    const [orderBy, setOrderBy] = useState<string>("roles");
    const [selected, setSelected] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [rolesSelectOpenState, setRolesSelectOpenState] = useState<any>({});
    const [authoritiesRolesMap, setAuthoritiesRolesMap] = useState<any>({});

    useEffect(() => {
        const selectOpenStateData: any = {};
        const authoritiesRolesMapData: any = {};
        for (let i = 0; i < tableData.length; i++) {
            selectOpenStateData[tableData[i].name] = false;
            authoritiesRolesMapData[tableData[i].name] = [...tableData[i].roles.map(r => r.name)];
        }
        setRolesSelectOpenState(selectOpenStateData);
        setAuthoritiesRolesMap(authoritiesRolesMapData);
    }, [tableData]);

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelected(tableData.map(authority => authority.name));
            return;
        }
        setSelected([]);
    };
    const handleRequestSort = (event: React.MouseEvent<HTMLElement>, property: string) => {
        const orderBy = property;
        let o: "asc" | "desc" = "desc";

        if (orderBy === property && order === "desc") {
            o = "asc";
        }
        setOrder(o);
        setOrderBy(orderBy);
    };
    function compareRolesField(a: IRole[], b: IRole[]) {
        return b.length - a.length;
    }
    function desc(a: any, b: any, orderBy: string) {
        if (b[orderBy] === null || b[orderBy] === undefined) {
            b[orderBy] = "";
        }
        if (a[orderBy] === null || a[orderBy] === undefined) {
            a[orderBy] = "";
        }
        if (orderBy === "roles") {
            return compareRolesField(a[orderBy], b[orderBy]);
        } else {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        }
    }
    function stableSort(array: any[], cmp: any) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a: any, b: any) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }
    function getSorting(order: "asc" | "desc", orderBy: string) {
        return order === "desc" ? (a: any, b: any) => desc(a, b, orderBy) : (a: any, b: any) => -desc(a, b, orderBy);
    }
    const handleClick = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };
    const handleRolesChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>, authorityName: string) => {
        let value: string[];
        if (!Array.isArray(e.target.value)) {
            value = [String(e.target.value)];
        } else {
            value = e.target.value;
        }
        setAuthoritiesRolesMap({
            ...authoritiesRolesMap,
            [authorityName]: value
        });
    };
    const handleRolesSelectOpen = (e: React.ChangeEvent<{}>, authorityName: string) => {
        let selectOpenStateData = {
            ...rolesSelectOpenState
        };
        selectOpenStateData[authorityName] = true;
        setRolesSelectOpenState(selectOpenStateData);
    };
    const handleRolesSelectClose = (e: React.ChangeEvent<{}>, authorityName: string) => {
        let selectOpenStateData = {
            ...rolesSelectOpenState
        };
        selectOpenStateData[authorityName] = false;
        setRolesSelectOpenState(selectOpenStateData);

        const updateData = getUpdates(authorityName);
        if (updateData) {
            updateRoles(updateData, authorityName);
        }
    };
    function getUpdates(authorityName: string) {
        let shouldUpdate: boolean = false;
        const currentData = authoritiesRolesMap[authorityName];
        const previousData = tableData.filter(item => item.name === authorityName)[0].roles.map(role => role.name);
        if (!Array.isArray(currentData) || !Array.isArray(previousData)) {
            return shouldUpdate;
        }
        if (currentData.length !== previousData.length) {
            shouldUpdate = true;
        }
        let data: { rolesToBeRemoved: string[]; rolesToBeAdded: string[] } = {
            rolesToBeRemoved: [],
            rolesToBeAdded: []
        };
        for (let i = 0; i < currentData.length; i += 1) {
            if (previousData.indexOf(currentData[i]) === -1) {
                data.rolesToBeAdded.push(currentData[i]);
                shouldUpdate = true;
            }
        }
        if (!shouldUpdate) return false;
        for (let i = 0; i < previousData.length; i += 1) {
            if (currentData.indexOf(previousData[i]) === -1) {
                data.rolesToBeRemoved.push(previousData[i]);
            }
        }
        return data;
    }
    const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    return (
        <Paper className={classes.root}>
            <AuthoritiesTableToolbar
                numSelected={selected.length}
                searchValue={searchValue}
                onSearchValueChange={onSearchValueChange}
            />
            <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                    <AuthoritiesTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={tableData.length}
                    />
                    <TableBody>
                        {stableSort(tableData, getSorting(order, orderBy)).map(
                            (authority: IMethodWithRoles, i: number) => {
                                const isSelected = selected.indexOf(authority.name) !== -1;
                                if (
                                    searchValue === "" ||
                                    authority.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                                ) {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={authority.name + i}
                                            selected={isSelected}
                                            style={{ display: "gird" }}
                                        >
                                            <TableCell padding="checkbox" style={{ flex: 1 }}>
                                                <Checkbox
                                                    checked={isSelected}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                        handleClick(event, authority.name)
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                padding="default"
                                                style={{ width: "18%" }}
                                            >
                                                {authority.serviceName}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                padding="default"
                                                style={{ width: "18%" }}
                                            >
                                                {authority.name}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                padding="default"
                                                style={{ width: "18%" }}
                                            >
                                                {authority.description}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                padding="default"
                                                style={{ width: "28%" }}
                                            >
                                                <FormControl>
                                                    <Select
                                                        multiple
                                                        value={authoritiesRolesMap[authority.name] || []}
                                                        onChange={(
                                                            e: React.ChangeEvent<{ name?: string; value: unknown }>
                                                        ) => handleRolesChange(e, authority.name)}
                                                        displayEmpty
                                                        renderValue={selected => {
                                                            return selected ? (
                                                                Array.isArray(selected) ? (
                                                                    selected.join(", ") || (
                                                                        <span style={{ color: "red" }}>No roles</span>
                                                                    )
                                                                ) : (
                                                                    <>{selected}</>
                                                                )
                                                            ) : (
                                                                <span style={{ color: "red" }}>No roles</span>
                                                            );
                                                        }}
                                                        open={!!rolesSelectOpenState[authority.name]}
                                                        onOpen={(e: React.ChangeEvent<{}>) => {
                                                            handleRolesSelectOpen(e, authority.name);
                                                        }}
                                                        onClose={(e: React.ChangeEvent<{}>) => {
                                                            handleRolesSelectClose(e, authority.name);
                                                        }}
                                                    >
                                                        {allRoles.map((role: IRole) => (
                                                            <MenuItem key={role.name} value={role.name}>
                                                                <Checkbox
                                                                    checked={
                                                                        (
                                                                            authoritiesRolesMap[authority.name] || []
                                                                        ).indexOf(role.name) > -1
                                                                    }
                                                                />
                                                                <ListItemText primary={role.name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                padding="default"
                                                style={{ width: "18%" }}
                                            >
                                                {authority.modifier} {authority.decorator || "Authorized"}
                                            </TableCell>
                                        </TableRow>
                                    );
                                } else {
                                    return <React.Fragment key={i} />;
                                }
                            }
                        )}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    );
};

export default withStyles(styles)(AuthoritiesTable);
