import React, { useState, ChangeEvent } from "react";

export function useFormInput<T>(initialValue: T, resetError?: () => any) {
    const [value, setValue] = useState<T>(initialValue);
    function onChange(e: ChangeEvent<any>) {
        resetError && resetError();
        setValue(e.target.value);
    }
    return {
        value,
        onChange,
    };
}
