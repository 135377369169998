import React, { useEffect, useRef, useState } from "react";
import withStyles, { StyleRules } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithStyles, Button } from "@material-ui/core";
import Tree from "../tree";
import Messages from "../tree/messages";
import Filters from "../tree/filters";
import SendMessage from "./sendMessage";
import { default as mqtt, MqttClient } from "mqtt";
import { MQTT_SERVER_URL } from "../../constants/app";
import { getAuthToken } from "../../api";
import MessagingDialog from "./messagingDialog";

const styles: StyleRules<string> = createStyles({
    messageExplorer: {
        display: "flex",
        width: "100vw",
        height: "calc(100vh - 64px)",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "auto",
        flex: 1,
    },
    treeAndSendMessageCmp: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
});

const MessageExplorer = ({ classes }: WithStyles<typeof styles>) => {
    const [messages, setMessages] = useState<string[]>([]);
    const [isRegex, setIsRegex] = useState<boolean>(false);
    const clientRef = useRef<MqttClient | null>(null);
    const [client, setClient] = useState<MqttClient | null>(null);

    const [hidePingMessages, setHidePingMessages] = useState<boolean>(false);
    const [hidePingSuccessMessages, setHidePingSuccessMessages] = useState<boolean>(false);
    const [showMessagingDialog, setShowMessagingDialog] = useState<boolean>(false);

    useEffect(() => {
        clientRef.current = mqtt.connect(MQTT_SERVER_URL, {
            keepalive: 60,
            username: getAuthToken(),
            password: "Admin",
        }); // you add a ws:// url here
        clientRef.current.subscribe("#");
        return () => {
            clientRef.current!.unsubscribe("#");
            clientRef.current!.end();
        };
    }, []);

    useEffect(() => {
        setClient(clientRef.current);
    }, [clientRef.current]);

    const updateMessages = (messages: string[]) => {
        setMessages(messages);
    };

    const [visibilityFilters, setVisibilityFilters] = useState<{
        searchByTopic: string;
        searchByMessageType: string;
    }>({
        searchByTopic: "",
        searchByMessageType: "",
    });

    const hidePingMessagesChanges = (hide: boolean) => {
        setHidePingMessages(hide);
    };
    const hidePingSuccessMessagesChanges = (hide: boolean) => {
        setHidePingSuccessMessages(hide);
    };

    function filtersChange(searchByTopic: string, searchByMessageType: string, isRegex: boolean) {
        setVisibilityFilters({
            searchByTopic,
            searchByMessageType,
        });
        setIsRegex(isRegex);
    }

    const filterByPing = (message: string) => {
        let visible = true;
        let messageType = "";
        try {
            messageType = JSON.parse(message).type;
        } catch {}
        if (messageType === "PING" && hidePingMessages) {
            visible = false;
        }
        return visible;
    };
    const filterByPingSuccess = (message: string) => {
        let visible = true;
        let messageType = "";
        try {
            messageType = JSON.parse(message).type;
        } catch {}
        if (messageType === "PING_SUCCESS" && hidePingSuccessMessages) {
            visible = false;
        }
        return visible;
    };
    function getNodeVisibility(topic: string, message: string) {
        return (
            filterByTopic(topic) &&
            filterByMessageType(message) &&
            filterByPing(message) &&
            filterByPingSuccess(message)
        );
    }

    // in search field topic parts must be strings separated with "/"
    // each depth respectively search in topic
    // also check with regex
    // examples
    // topic: development/devices/server
    // searchByTopic: development/devices/server || will get
    // searchByTopic: development/*/server || will get
    // searchByTopic: */*/ser || will get
    // searchByTopic: develop/*/ser || will get
    // searchByTopic: develop/* || will get
    // searchByTopic: Develop/* || will not get
    // searchByTopic: development/devices/server/ || will not get
    // searchByTopic: /development/devices/server || will not get
    // searchByTopic: development/devices/server/asd || will not get
    // searchByTopic: developmentasd/devices/server || will not get
    function filterByTopic(topic: string) {
        let visible = true;
        if (isRegex) {
            const regex = new RegExp(visibilityFilters.searchByTopic);
            if (regex.test(topic)) {
                return visible;
            } else {
                return false;
            }
        }
        if (!visibilityFilters.searchByTopic) {
            return visible;
        }
        const topicArr = topic.split("/");
        const filterArr = visibilityFilters.searchByTopic.split("/");
        for (let i = 0; i < filterArr.length; i++) {
            if (filterArr[i] === "*") {
                continue;
            }
            if (topicArr[i] && topicArr[i].indexOf(filterArr[i]) !== -1) {
                continue;
            }
            visible = false;
            break;
        }
        return visible;
    }

    // search by message type
    // search is case insensitive
    // examples
    // message: {"type": "PING_SUCCESS", "payload": {"uptime": "23 days, ....}}
    // searchByMessageType:  || will get
    // searchByMessageType: ping_success || will get
    // searchByMessageType: ping || will get
    // searchByMessageType: p || will get
    // searchByMessageType: '' || will not get
    // searchByMessageType: asd || will not get
    // searchByMessageType: PING_SUCCESSa || will not get
    function filterByMessageType(message: string) {
        const { searchByMessageType } = visibilityFilters;
        if (!searchByMessageType) {
            return true;
        }
        let messageType;
        try {
            const messageObj = JSON.parse(message);
            messageType = messageObj.type;
        } catch (e) {}
        return !!(messageType && messageType.toLowerCase().includes(searchByMessageType.toLowerCase()));
    }

    const sendMessage = function (topic: string, message: string) {
        const client: MqttClient | null = clientRef.current;
        if (!client) return;
        client.publish(topic, message);
    };

    return (
        <div className={classes.messageExplorer}>
            <Filters
                filtersChange={filtersChange}
                hidePingMessages={hidePingMessages}
                hidePingSuccessMessages={hidePingSuccessMessages}
                hidePingMessagesChanges={hidePingMessagesChanges}
                hidePingSuccessMessagesChanges={hidePingSuccessMessagesChanges}
            />
            <div className={classes.treeAndSendMessageCmp}>
                <Tree
                    client={client}
                    visibilityFilters={visibilityFilters}
                    getNodeVisibility={getNodeVisibility}
                    updateMessages={updateMessages}
                />
                <SendMessage sendMessage={sendMessage} />
                <div>
                    <Button color="primary" variant="contained" onClick={() => setShowMessagingDialog(true)}>
                        Send Message to Device
                    </Button>
                </div>
            </div>
            {showMessagingDialog && (
                <MessagingDialog
                    isOpen={showMessagingDialog}
                    sendMessage={sendMessage}
                    handleClose={() => setShowMessagingDialog(false)}
                />
            )}
            <Messages messages={messages} />
        </div>
    );
};

export default withStyles(styles)(MessageExplorer);
