import React, { useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

const RolesTableHead = (props: {
    numSelected: number;
    order: "asc" | "desc" | undefined;
    orderBy: string;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onRequestSort: (event: React.MouseEvent<HTMLElement>, property: string) => void;
    rowCount: number;
}) => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    useEffect(() => {}, []);

    const createSortHandler = (property: string) => (event: React.MouseEvent<HTMLElement>) => {
        onRequestSort(event, property);
    };

    const rows = [
        { id: "name", label: "Role Name" },
        { id: "authorities", label: "Authorities" },
        { id: "access", label: "Public Access" },
        { id: "allOrganization", label: "All organization" },
        { id: "organizationIds", label: "Organizations" },
        { id: "uiConfigs", label: "Ui configs" },
    ];

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell>
                {rows.map((row, index) => (
                    <TableCell
                        key={`${row.id}-${index}`}
                        align="left"
                        padding="default"
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <Tooltip title="Sort" placement="bottom-end" enterDelay={300}>
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default RolesTableHead;
