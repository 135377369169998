import React, { useEffect, useState } from "react";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import QuestionAnswerIcon from "@material-ui/icons/HelpOutline";
import createStyles from "@material-ui/core/styles/createStyles";
import EmailDomainsTableToolbar from "./emailDomainsTableToolbar";
import EmailDomainsTableHead from "./emailDomainsTableHead";
import { IEmailDomain, IEmailDomainsTableDataIsValidField } from "../../interfaces/emailDomains";

const styles = () =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flex: 1
        },
        table: {
            minWidth: 1020
        },
        tableWrapper: {
            overflowX: "auto",
            flex: 1
        }
    });

const EmailDomainsTable = ({
    classes,
    tableData,
    blockDomain,
    unBlockDomain,
    updateDomain
}: WithStyles<typeof styles> & {
    tableData: IEmailDomain[];
    blockDomain: (domainId: string) => void;
    unBlockDomain: (domainId: string) => void;
    updateDomain: (domainId: string, description: string) => void;
}) => {
    const [order, setOrder] = useState<"asc" | "desc">("asc");
    const [orderBy, setOrderBy] = useState<string>("name");
    const [selected, setSelected] = useState<string[]>([]);
    const [descriptionEditMode, setDescriptionEditMode] = useState<boolean>(false);
    const [editingDescription, setEditingDescription] = useState<string>("");
    const [editingDomainId, setEditingDomainId] = useState<string | null>("");

    useEffect(() => {
        setDescriptionEditMode(false);
    }, [tableData]);

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelected(tableData.map(n => n.id));
            return;
        }
        setSelected([]);
    };
    const handleRequestSort = (event: React.MouseEvent<HTMLElement>, property: string) => {
        const orderBy = property;
        let o: "asc" | "desc" = "desc";

        if (orderBy === property && order === "desc") {
            o = "asc";
        }
        setOrder(o);
        setOrderBy(orderBy);
    };

    function compareIsValidField(a: IEmailDomainsTableDataIsValidField, b: IEmailDomainsTableDataIsValidField) {
        const comparisionMap: IEmailDomainsTableDataIsValidField[] = ["unknown", "invalid", "valid"];
        return comparisionMap.indexOf(a) - comparisionMap.indexOf(b);
    }

    function desc(a: any, b: any, orderBy: string) {
        if (b[orderBy] === null || b[orderBy] === undefined) {
            b[orderBy] = "";
        }
        if (a[orderBy] === null || a[orderBy] === undefined) {
            a[orderBy] = "";
        }
        if (orderBy === "isValid") {
            return compareIsValidField(a[orderBy], b[orderBy]);
        } else {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        }
    }
    function stableSort(array: IEmailDomain[], cmp: any) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a: any, b: any) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0] as IEmailDomain);
    }
    function getSorting(order: "asc" | "desc", orderBy: string) {
        return order === "desc" ? (a: any, b: any) => desc(a, b, orderBy) : (a: any, b: any) => -desc(a, b, orderBy);
    }
    const handleClick = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    function onDescriptionDoubleClick(desc: string, domainId: string) {
        if (descriptionEditMode) {
            onCancelEditingDescriptionClick();
        }
        setEditingDescription(desc);
        setEditingDomainId(domainId);
        setDescriptionEditMode(true);
    }

    function handleDescriptionChange(e: React.ChangeEvent<HTMLInputElement>) {
        setEditingDescription(e.target.value);
    }

    function onSaveDescriptionClick() {
        if (editingDomainId) {
            updateDomain(editingDomainId, editingDescription);
        }
    }

    function onCancelEditingDescriptionClick() {
        setEditingDomainId(null);
        setEditingDescription("");
        setDescriptionEditMode(false);
    }

    return (
        <Paper className={classes.root}>
            <EmailDomainsTableToolbar numSelected={selected.length} />
            <div className={classes.tableWrapper}>
                <Table stickyHeader className={classes.table} aria-labelledby="tableTitle">
                    <EmailDomainsTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={tableData.length}
                    />
                    <TableBody>
                        {stableSort(tableData, getSorting(order, orderBy)).map((emailDomain: IEmailDomain) => {
                            const isSelected = selected.indexOf(emailDomain.id) !== -1;
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    aria-checked={isSelected}
                                    tabIndex={-1}
                                    key={emailDomain.id}
                                    selected={isSelected}
                                    style={{ display: "gird" }}
                                >
                                    <TableCell padding="checkbox" style={{ flex: 1 }}>
                                        <Checkbox
                                            checked={isSelected}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                handleClick(event, emailDomain.id)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row" padding="default" style={{ flex: 2 }}>
                                        {emailDomain.name}
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        padding="default"
                                        onDoubleClick={() =>
                                            onDescriptionDoubleClick(emailDomain.description || "", emailDomain.id)
                                        }
                                    >
                                        {descriptionEditMode && editingDomainId === emailDomain.id ? (
                                            <>
                                                <Input
                                                    value={editingDescription}
                                                    style={{ marginRight: "5px" }}
                                                    onChange={handleDescriptionChange}
                                                />
                                                <Fab
                                                    size="small"
                                                    style={{ marginRight: "5px" }}
                                                    onClick={onSaveDescriptionClick}
                                                >
                                                    <SaveIcon htmlColor="green" />
                                                </Fab>
                                                <Fab size="small" onClick={onCancelEditingDescriptionClick}>
                                                    <CancelIcon color="error" />
                                                </Fab>
                                            </>
                                        ) : (
                                            <>{emailDomain.description}</>
                                        )}
                                    </TableCell>
                                    {emailDomain.isValid === "valid" && (
                                        <>
                                            <TableCell component="th" scope="row" padding="default">
                                                <CheckIcon htmlColor="green" />
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="default">
                                                <Button
                                                    onClick={() => blockDomain(emailDomain.id)}
                                                    color="secondary"
                                                    size="small"
                                                    variant="outlined"
                                                >
                                                    Block
                                                </Button>
                                            </TableCell>
                                        </>
                                    )}
                                    {emailDomain.isValid === "invalid" && (
                                        <>
                                            <TableCell component="th" scope="row" padding="default">
                                                <BlockIcon color="error" />
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="default">
                                                <Button
                                                    onClick={() => unBlockDomain(emailDomain.id)}
                                                    color="primary"
                                                    size="small"
                                                    variant="outlined"
                                                >
                                                    UnBlock
                                                </Button>
                                            </TableCell>
                                        </>
                                    )}
                                    {emailDomain.isValid === "unknown" && (
                                        <>
                                            <TableCell component="th" scope="row" padding="default">
                                                <QuestionAnswerIcon color="primary" />
                                            </TableCell>
                                            <TableCell component="th" scope="row" padding="default">
                                                <Button
                                                    onClick={() => unBlockDomain(emailDomain.id)}
                                                    style={{
                                                        marginRight: "5px",
                                                        color: "green",
                                                        borderColor: "green"
                                                    }}
                                                    size="small"
                                                    variant="outlined"
                                                >
                                                    Validate
                                                </Button>
                                                <Button
                                                    onClick={() => blockDomain(emailDomain.id)}
                                                    color="secondary"
                                                    size="small"
                                                    variant="outlined"
                                                >
                                                    Block
                                                </Button>
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    );
};

export default withStyles(styles)(EmailDomainsTable);
