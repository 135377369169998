import React from "react";
import {
    withStyles,
    Theme,
    createStyles,
    DialogTitle,
    Dialog,
    DialogContent,
    TextField,
    Button,
    DialogActions,
} from "@material-ui/core";

import { useFormInput } from "../../utils/hooks";
import { ICouponCode } from ".";

const styles = () =>
    createStyles({
        dialog: {
            "@media (min-width: 600px)": {
                // height: "375px",
                minWidth: "350px",
            },
        },
        dialogContent: {
            padding: 20,
            minHeight: "215px",

            // height: "215px",
            borderTop: "1px solid rgba(80, 76, 76, 0.14)",
        },
        dialogActions: {
            padding: "13px 20px",
            margin: 0,
        },
        emptyPage: {
            padding: "12px 24px",
        },
        nav: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        listItem: {
            paddingLeft: 24,
            paddingRight: 24,
            borderBottom: "1px solid rgba(80, 76, 76, 0.14)",
        },
        listItemText: {
            "&>span": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
        },
        listItemDeleted: {
            backgroundColor: "rgba(0, 0, 0, 0.14)",
        },
        loaderWrapper: {
            display: "flex",
            justifyContent: "center",
            padding: "30px",
        },
    });

const AddCouponCodeDialog = ({
    fullScreen = false,
    classes,
    onClose,
    addCouponCodeHandler,
}: {
    fullScreen?: boolean;
    classes: any;
    addCouponCodeHandler: (data: ICouponCode) => Promise<void>;
    onClose: () => any;
}) => {
    const { value: name, onChange: onNameChange } = useFormInput("");
    const { value: description, onChange: onDescriptionChange } = useFormInput("");
    const { value: code, onChange: onCodeChange } = useFormInput("");
    const { value: duration, onChange: onDurationChange } = useFormInput("");
    const { value: pricingPlan, onChange: onPricingPlanChange } = useFormInput("");
    const addClick = () => {
        addCouponCodeHandler({
            name,
            code,
            description,
            duration: parseFloat(duration),
        });
    };
    return (
        <Dialog
            //    fullWidth
            maxWidth="xl"
            open
            classes={{
                paper: classes.dialog,
            }}
            fullScreen={fullScreen !== undefined ? fullScreen : true}
            onClose={onClose}
        >
            <DialogTitle>Add CouponCode</DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextField required value={name} label="Name" onChange={onNameChange} margin="normal" />
                    <TextField
                        required
                        value={description}
                        label="Description"
                        onChange={onDescriptionChange}
                        margin="normal"
                    />
                    <TextField required value={code} label="Code" onChange={onCodeChange} margin="normal" />
                    <TextField required value={duration} label="Duration" onChange={onDurationChange} margin="normal" />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={addClick} color="primary" autoFocus>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default withStyles(styles)(AddCouponCodeDialog);
