import React, { useState } from "react";
import withStyles, { StyleRules } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { WithStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const styles: StyleRules<string> = createStyles({
    sendMessageWrapper: {
        display: "flex",
        flexDirection: "column"
    }
});

const SendMessage = ({
    classes,
    sendMessage
}: WithStyles<typeof styles> & { sendMessage: (topic: string, message: string) => void }) => {
    const [topic, setTopic] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    const onTopicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTopic(e.target.value);
    };
    const onMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
    };
    const onSendBtnClick = () => {
        sendMessage(topic, message);
    };

    return (
        <div className={classes.sendMessageWrapper}>
            <TextField value={topic} label="Topic" onChange={onTopicChange} margin="normal" />
            <TextField value={message} label="Message" onChange={onMessageChange} rows={4} multiline margin="normal" />
            <Button color="primary" variant="contained" onClick={onSendBtnClick}>
                Send
            </Button>
        </div>
    );
};

export default withStyles(styles)(SendMessage);
