import { BASE_DOMAIN } from "../constants/app";
import { makeDelete, makeGet, makePatch, makePost } from "./index";

const API_URL = {
    ROLES: () => `${BASE_DOMAIN}/v1/roles`,
    UPDATE_ROLE: (roleId: string) => `${BASE_DOMAIN}/v1/roles/${roleId}`
};

export function getRoles() {
    return makeGet(API_URL.ROLES(), {});
}

export function updateRole(roleId: string, body: any) {
    return makePatch(API_URL.UPDATE_ROLE(roleId), body, {});
}

export function addRole(data: any) {
    return makePost(API_URL.ROLES(), { data }, {});
}

export function deletedRoles(data: any) {
    return makeDelete(API_URL.ROLES(), { data }, {});
}
