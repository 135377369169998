import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";

const DeleteRoleDialog = ({
    isOpen,
    handleClose,
    handleDelete,
    fullScreen,
    deleteRoleError
}: {
    isOpen: boolean;
    handleClose: () => void;
    handleDelete: () => void;
    fullScreen?: boolean;
    deleteRoleError: string;
}) => {
    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {
            onDeleteBtnClick();
        }
    };

    const inputProps = {
        onKeyDown: onKeyDown
    };

    const onDeleteBtnClick = () => {
        handleDelete();
    };

    return (
        <Dialog fullScreen={fullScreen} open={isOpen} onClose={handleClose} aria-labelledby="add-role">
            <DialogTitle id="add-role">Delete Role</DialogTitle>
            <DialogContent>
                <div>Are you sure want to delete selected role/roles?</div>
                {!!deleteRoleError && <FormHelperText error>{deleteRoleError}</FormHelperText>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onDeleteBtnClick} color="primary" autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteRoleDialog;
