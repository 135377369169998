import React, { useState, useEffect } from "react";
import { Dialog, withStyles, WithStyles, DialogActions, Checkbox } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, Button, Tooltip } from "@material-ui/core";
import { IPackage } from "../../interfaces";
import { TableCellProps } from "react-virtualized";
import Grid, { IGridColumn } from "../grid";
import { getPackages } from "../../api/package";

const styles = createStyles({
    card: {
        width: 1200,
        height: 600,
    },
    gridWrapper: {
        minHeight: 600,
        height: 600,
        flex: 4,
    },
    gridDefaultContextRenderer: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    textFieldsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    },
    nextButton: {
        float: "right",
    },
    selectFormControl: {
        margin: "8px 0",
    },
});
const AddDeletePackageDialog = ({
    classes,
    isOpen,
    onClose,
    devicePackages,
    onSave,
    deviceId,
    action,
}: WithStyles<typeof styles> & {
    isOpen: boolean;
    onClose: () => void;
    onSave?: (deviceId: string, packageIds: string[]) => void;
    deviceId: string;
    action: "add" | "delete" | "edit" | "";
    devicePackages: string[];
}) => {
    const [packages, setPackages] = useState<IPackage[]>([]);
    const [selectedPackages, setSelectedPackages] = useState<string[]>([]);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    useEffect(() => {
        devicePackages && setSelectedPackages([...devicePackages]);
    }, [devicePackages]);

    useEffect(() => {
        setIsDataLoading(true);
        (async () => {
            try {
                const data = await getPackages();
                if (action === "delete") {
                    setPackages(data.data.filter((f) => devicePackages.indexOf(f._id) !== -1));
                } else {
                    setPackages(data.data);
                }
            } catch {
            } finally {
                setIsDataLoading(false);
            }
        })();
    }, [action]);

    const onCreateBtnClick = () => {
        const data =
            action === "delete"
                ? packages.filter((p) => selectedPackages.indexOf(p._id) === -1).map((p) => p._id)
                : selectedPackages;
        onSave && onSave(deviceId, data);
    };
    const gridDefaultContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        let data = <div className={classes.gridDefaultContextRenderer}>{JSON.stringify(props.cellData)}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={props.cellData} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };
    const checkChange = (id: string) => (e: any) => {
        if (!e.target.checked) {
            selectedPackages.splice(selectedPackages.indexOf(id), 1);
        } else {
            selectedPackages.push(id);
        }
        setSelectedPackages([...selectedPackages]);
    };
    const checkContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        const checked = selectedPackages.indexOf(props.cellData) !== -1;
        return <Checkbox checked={checked} onChange={checkChange(props.cellData)} />;
    };
    const columns: IGridColumn[] = [
        {
            dataKey: "_id",
            width: 80,
            label: "Select",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: checkContextRenderer,
        },
        {
            dataKey: "_id",
            width: 270,
            label: "ID",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "name",
            width: 200,
            label: "Name",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "description",
            width: 350,
            label: "Description",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "version",
            width: 100,
            label: "Version",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "status",
            width: 100,
            label: "Status",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        // {
        //     dataKey: "runStrategy",
        //     width: 200,
        //     label: "runStrategy",
        //     flexGrow: 1,
        //     flexShrink: 1,
        //     cellContentRenderer: gridDefaultContextRenderer
        // }
        // {
        //     dataKey: "filters",
        //     width: 100,
        //     label: "Filters",
        //     flexGrow: 1,
        //     flexShrink: 1,
        //     cellContentRenderer: devicesCellRenderer
        // },
    ];
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth={false}>
            <DialogTitle>Manage Packages</DialogTitle>
            <DialogContent>
                <div className={classes.card}>
                    <div className={classes.gridWrapper}>
                        <Grid
                            data={packages}
                            columns={columns}
                            enablePagination={false}
                            gridToolbarTitle="Packages"
                            searchValueChangeTimeOutForCall={1000}
                            isDataLoading={isDataLoading}
                            rowsPerPageOptions={[100, 250, 500]}
                            labelRowsPerPage={window.innerWidth > 992 ? undefined : null}
                        />
                    </div>
                </div>{" "}
            </DialogContent>
            <DialogActions>
                <Button className={classes.nextButton} onClick={onCreateBtnClick}>
                    Save
                </Button>
                <Button className={classes.nextButton} onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default withStyles(styles)(AddDeletePackageDialog);
