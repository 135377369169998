import { BASE_DOMAIN } from "../constants/app";
import { makeGet, makePost } from "./index";
import { ICouponCode } from "../components/coupon-code";

const API_URL = {
    GET_COUPON_CODES: (page: number, rowsPerPage: number, searchValue = "") =>
        `${BASE_DOMAIN}/v1/coupon?page=${page}&rowsPerPage=${rowsPerPage}&searchValue=${searchValue}`,
    ADD_COUPON_CODES: () => `${BASE_DOMAIN}/v1/coupon`,
};

export function getCouponCodes(page: number, rowsPerPage: number, searchValue?: string) {
    return makeGet(API_URL.GET_COUPON_CODES(page, rowsPerPage, searchValue), {});
}

export function addCouponCode(body: ICouponCode) {
    return makePost(API_URL.ADD_COUPON_CODES(), body, {});
}
