import React, { useState, useEffect } from "react";
import { Dialog, TextField, withStyles, WithStyles, DialogActions } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, Button } from "@material-ui/core";
import semver from "semver";

const styles = createStyles({
    card: {
        width: 300,
        height: 300
    },
    textFieldsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    nextButton: {
        float: "right"
    },
    selectFormControl: {
        margin: "8px 0"
    }
});
const AddOrganizationDialog = ({
    classes,
    isOpen,
    onClose,
    onSave,
    packageInfo
}: WithStyles<typeof styles> & {
    isOpen: boolean;
    onClose: () => void;
    onSave: (data: { name: string; description: string; _id?: string }) => void;
    packageInfo: { name?: string; description?: string; _id?: string };
}) => {
    const [name, setName] = useState<string>(packageInfo.name || "");
    const [description, setDescription] = useState<string>(packageInfo.description || "");

    useEffect(() => {
        if (packageInfo) {
            setName(packageInfo.name || "");
            setDescription(packageInfo.description || "");
        }
    }, [packageInfo]);
    const [isValidSemVersion, setIsValidSemVersion] = useState<boolean>(true);
    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (name) {
            case "name":
                setName(event.target.value);
                break;
            case "description":
                setDescription(event.target.value);
                break;
        }
    };
    const onCreateBtnClick = () => {
        onSave({ name, description, _id: packageInfo._id });
    };
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Add Package</DialogTitle>
            <DialogContent>
                <div className={classes.card}>
                    <div className={classes.textFieldsWrapper}>
                        <TextField
                            id="standard-name"
                            label="Name"
                            value={name}
                            onChange={handleChange("name")}
                            margin="normal"
                        />
                        <TextField
                            id="standard-multiline-flexible"
                            label="Description"
                            multiline
                            rowsMax="4"
                            value={description}
                            onChange={handleChange("description")}
                            margin="normal"
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button className={classes.nextButton} onClick={onClose}>
                    Cancel
                </Button>
                <Button className={classes.nextButton} onClick={onCreateBtnClick}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default withStyles(styles)(AddOrganizationDialog);
