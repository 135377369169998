import React, { useContext, useEffect, useState } from "react";
import EmailDomainsTable from "./emailDomainsTable";
import { IEmailDomain } from "../../interfaces/emailDomains";
import { updateDomain, getEmailDomains } from "../../api/email-domains";
import { AppDispatch } from "../../context";

const EmailDomains = () => {
    const [tableData, setTableData] = useState<IEmailDomain[]>([]);
    const dispatch = useContext(AppDispatch)!;

    useEffect(() => {
        getEmailDomains()
            .then(response => {
                const domains = (response && response.data) || [];
                const tableData = domains.map((d: any) => {
                    return { id: d._id, name: d.name, description: d.description, isValid: d.isValid };
                });
                setTableData(tableData);
            })
            .catch(e => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    }, []);
    function updateTableData(updatedItem: any) {
        const domainForTable: IEmailDomain = updatedItem && {
            id: updatedItem._id,
            name: updatedItem.name,
            description: updatedItem.description,
            isValid: updatedItem.isValid
        };
        const index = tableData.indexOf(tableData.filter(td => td.id === domainForTable.id)[0]);
        const newTableData = [...tableData];
        newTableData.splice(index, 1);
        newTableData.push(domainForTable);
        setTableData(newTableData);
    }
    const blockDomain = (domainId: string) => {
        updateDomain(domainId, { isValid: "invalid" })
            .then((response: any) => {
                updateTableData(response && response.data);
            })
            .catch(e => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };
    const unBlockDomain = (domainId: string) => {
        updateDomain(domainId, { isValid: "valid" })
            .then((response: any) => {
                updateTableData(response && response.data);
            })
            .catch(e => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };
    const doUpdateDomain = (domainId: string, description: string) => {
        updateDomain(domainId, { description })
            .then(response => {
                updateTableData(response && response.data);
            })
            .catch(e => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };

    return (
        <EmailDomainsTable
            updateDomain={doUpdateDomain}
            blockDomain={blockDomain}
            unBlockDomain={unBlockDomain}
            tableData={tableData}
        />
    );
};

export default EmailDomains;
