import React, { useContext, useEffect, useState } from "react";
import { ITunnelEngine, IUser } from "../../interfaces/users";
import { withStyles, createStyles, WithStyles, IconButton } from "@material-ui/core";
import { AppDispatch } from "../../context";
import Grid, { IGridColumn } from "../grid";
import { TableCellProps } from "react-virtualized";
import Tooltip from "@material-ui/core/Tooltip";
import { getTunnelEngines, updateTunnelEngines } from "../../api/tunnel-engines";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import NotCheckedIcon from "@material-ui/icons/Close";
import EditTunnelEnginDialog from "./editDialog";
const styles = () =>
    createStyles({
        root: {
            display: "flex",
            flex: 1,
            height: "calc(100vh - 72px)",
            flexDirection: "row",
        },
        gridWrapper: {
            minHeight: 300,
            flex: 4,
        },
        gridDefaultContextRenderer: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    });

const TunnelEngines = ({ classes }: WithStyles<typeof styles> & {}) => {
    const [tunnelEngines, setTunnelEngines] = useState<IUser[]>([]);
    const [tunnelEngine, setTunnelEngine] = useState<ITunnelEngine | undefined>(undefined);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
    const dispatch = useContext(AppDispatch)!;
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    useEffect(() => {
        loadData();
    }, []);
    const loadData = () => {
        setIsDataLoading(true);
        getTunnelEngines()
            .then((response) => {
                setIsDataLoading(false);
                const data = (response && response.data) || [];
                setTunnelEngines(data);
            })
            .catch((e) => {
                setIsDataLoading(false);
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };
    const gridDefaultContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        const data = <div className={classes.gridDefaultContextRenderer}>{props.cellData}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={props.cellData} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };

    const gridBooleanFieldContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        return (
            <div className={classes.gridDefaultContextRenderer}>
                {props.cellData ? <CheckIcon htmlColor="green" /> : <NotCheckedIcon htmlColor="red" />}
            </div>
        );
    };
    const editCellRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => (
        <IconButton
            onClick={() => {
                setTunnelEngine(props.rowData);
                setIsEditDialogOpen(true);
            }}
        >
            <EditIcon />
        </IconButton>
    );

    const columns: IGridColumn[] = [
        {
            dataKey: "name",
            width: 200,
            label: "Name",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "status",
            width: 200,
            label: "Status",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "registered",
            width: 200,
            label: "Registered",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridBooleanFieldContextRenderer,
        },
        {
            dataKey: "reserved",
            width: 200,
            label: "Reserved",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridBooleanFieldContextRenderer,
        },
        {
            dataKey: "portRange",
            width: 200,
            label: "Port Range",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "address",
            width: 200,
            label: "Address",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "publicIpAddress",
            width: 200,
            label: "Public Ip Address",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "privateIpAddress",
            width: 200,
            label: "Private Ip Address",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "addressType",
            width: 200,
            label: "Address Type to Use",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        { dataKey: "", width: 50, label: "Edit", flexGrow: 1, flexShrink: 1, cellContentRenderer: editCellRenderer },
    ];
    const updateEngine = async (id: string, data: Partial<ITunnelEngine>) => {
        try {
            await updateTunnelEngines(id, data);
            setIsEditDialogOpen(false);
            loadData();
        } catch (e) {
            dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.gridWrapper}>
                <Grid
                    data={tunnelEngines}
                    columns={columns}
                    gridToolbarTitle="Tunnel Engines"
                    isDataLoading={isDataLoading}
                />
                {tunnelEngine && (
                    <EditTunnelEnginDialog
                        isOpen={isEditDialogOpen}
                        onClose={() => {
                            setIsEditDialogOpen(false);
                        }}
                        tunnelEngin={tunnelEngine}
                        onSave={updateEngine}
                    />
                )}
            </div>
        </div>
    );
};

export default withStyles(styles)(TunnelEngines);
