import React from "react";
import withStyles, { StyleRules, WithStyles } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import classNames from "classnames";
import { Tooltip } from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactJson from "react-json-view";

const styles: StyleRules<string> = createStyles({
    messages: {
        display: "flex",
        width: "100vw",
        minWidth: 700,
        flex: 1,
        flexDirection: "column",
        overflow: "auto",
        minHeight: 150,
        "& >div:nth-child(even)": {
            backgroundColor: "#efefef"
        }
    },
    message: {
        display: "flex"
    },
    messageValue: {
        flex: 2,
        maxWidth: 250,
        minWidth: 50,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        padding: 4,
        cursor: "pointer"
    },
    messageType: {
        flex: 1,
        minWidth: "fit-content"
    },
    messageLabel: {
        flex: 1,
        color: "#6e6e6e",
        padding: 4,
        whiteSpace: "nowrap",
        maxWidth: "fit-content"
    },
    errorLabel: {
        color: "rgba(255, 0, 0, .8)"
    },
    error: {
        color: "rgba(255, 0, 0, 1)"
    },
    payloadWrapper: {
        flex: 2,
        display: "flex"
    }
});

const Messages = ({ classes, messages }: WithStyles<typeof styles> & { messages: string[] }) => {
    const onMessageValueClick = (value: string) => {};

    const renderMessage = (m: string, i: number) => {
        let message: string = "";
        let type: string = "";
        let payload: string = "";
        let correlationId: string = "";
        let error: string = "";
        try {
            const msgObj: any = JSON.parse(m);
            type = msgObj.type;
            payload = msgObj.payload;
            correlationId = msgObj.correlationId;
            error = msgObj.error;
        } catch (e) {
            return (
                <div key={`message-${i}`}>
                    <div>{message}</div>
                </div>
            );
        }

        return (
            <div key={`message-${i}`} className={classes.message}>
                {type && (
                    <div className={classNames(classes.messageValue, classes.messageType)}>
                        <strong>{type}</strong>
                    </div>
                )}
                {payload &&
                    (typeof payload === "object" ? (
                        <ReactJson
                            src={payload}
                            name="Payload"
                            collapsed
                            collapseStringsAfterLength={20}
                            displayObjectSize={false}
                            displayDataTypes={false}
                            style={{ padding: 5 }}
                        />
                    ) : (
                        <>
                            <div className={classes.messageLabel}>Payload - </div>
                            <Tooltip
                                className={classes.messageValue}
                                title={
                                    <>
                                        <div style={{ fontStyle: "italic" }}>Click to copy value</div>
                                        <div>{String(payload)}</div>
                                    </>
                                }
                            >
                                <CopyToClipboard text={String(payload)}>
                                    <div
                                        onClick={() => {
                                            onMessageValueClick(String(payload));
                                        }}
                                    >
                                        {String(payload)}
                                    </div>
                                </CopyToClipboard>
                            </Tooltip>
                        </>
                    ))}
                {correlationId && (
                    <>
                        <div className={classes.messageLabel}>Correlation Id - </div>
                        <Tooltip
                            className={classes.messageValue}
                            title={
                                <>
                                    <div style={{ fontStyle: "italic" }}>Click to copy value</div>
                                    <div>{String(correlationId)}</div>
                                </>
                            }
                        >
                            <CopyToClipboard text={String(correlationId)}>
                                <div
                                    onClick={() => {
                                        onMessageValueClick(String(correlationId));
                                    }}
                                >
                                    {String(correlationId)}
                                </div>
                            </CopyToClipboard>
                        </Tooltip>
                    </>
                )}
                {error && (
                    <>
                        <div className={classNames(classes.messageLabel, classes.errorLabel)}>Error - </div>
                        <Tooltip
                            className={classNames(classes.messageValue, classes.error)}
                            title={
                                <>
                                    <div style={{ fontStyle: "italic" }}>Click to copy value</div>
                                    <div>{String(error)}</div>
                                </>
                            }
                        >
                            <CopyToClipboard text={String(error)}>
                                <div
                                    onClick={() => {
                                        onMessageValueClick(String(error));
                                    }}
                                >
                                    {String(error)}
                                </div>
                            </CopyToClipboard>
                        </Tooltip>
                    </>
                )}
            </div>
        );
    };

    return <div className={classes.messages}>{messages.map((m: string, i: number) => renderMessage(m, i))}</div>;
};

export default withStyles(styles)(Messages);
