import { BASE_DOMAIN } from "../constants/app";
import { ITunnelEngine } from "../interfaces/users";
import { makeGet, makePatch } from "./index";

const API_URL = {
    GET_TUNNEL_ENGINES: () => `${BASE_DOMAIN}/v1/all-tunnel-engines`,
    UPDATE_TUNNEL_ENGINE: (id: string) => `${BASE_DOMAIN}/v1/te/${id}`,
};

export function getTunnelEngines() {
    return makeGet(API_URL.GET_TUNNEL_ENGINES(), {});
}
export function updateTunnelEngines(id: string, data: Partial<ITunnelEngine>) {
    return makePatch(API_URL.UPDATE_TUNNEL_ENGINE(id), data, {});
}
