import React, { useContext, useEffect, useState } from "react";
import { IUser, IConsumerRole } from "../../interfaces/users";
import { getUsersPerMonth, getUsersWithStatistics } from "../../api/users";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core";
import { AppDispatch } from "../../context";
import Grid, { IGridColumn } from "../grid";
import { TableCellProps } from "react-virtualized";
import InfoDialog from "./infoDialog";
import TunnelsInfoDialog from "./tunnelsInfoDialog";
import UsersPerMonth from "./usersPerMonthChart";
import UsersGrowth from "./usersGrowthChart";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightIcon from "@material-ui/icons/ArrowRightAlt";
import ChartIcon from "@material-ui/icons/ShowChart";
import GridIcon from "@material-ui/icons/ViewList";
import Fab from "@material-ui/core/Fab";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import { IMethod } from "../../interfaces/authorities";
import { getRolesAllMethods } from "../../api/methods";
import { ServiceMethodsContext } from "../../context/role";
import { updateConsumerRole } from "../../api/consumer-roles";
import CheckedIcon from "@material-ui/icons/Check";
import XIcon from "@material-ui/icons/Close";
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import MultiSelectDialog from "../shared/multiSelectDialog";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flex: 1,
            height: "calc(100vh - 72px)",
            flexDirection: "row",
        },
        chartsWrapper: {
            display: "flex",
            flex: 3,
            flexDirection: "row",
            overflow: "auto",
            [theme.breakpoints.down("md")]: {
                flexDirection: "column",
            },
        },
        gridWrapper: {
            minHeight: 300,
            flex: 4,
        },
        chart: {
            flex: 1,
        },
        arrowRightIcon: {
            [theme.breakpoints.down("sm")]: {
                position: "absolute",
                bottom: 0,
                zIndex: 9,
            },
        },
        navigationButtons: {
            display: "flex",
            flexDirection: "column",
        },
        navigationButton: {
            margin: 5,
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        eyeButton: {
            marginLeft: 10,
            padding: 8,
            [theme.breakpoints.down("sm")]: {
                padding: 2,
            },
        },
        devicesAndTunnelsWrapper: {
            minWidth: 55,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",
        },
        gridDefaultContextRenderer: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    });

const Users = ({ classes }: WithStyles<typeof styles> & {}) => {
    const [usersWithStatistics, setUsersWithStatistics] = useState<IUser[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(100);
    const [count, setCount] = useState<number>(0);
    const dispatch = useContext(AppDispatch)!;
    const [showInfoDialog, setShowInfoDialog] = useState<boolean>(false);
    const [dialogInfo, setDialogInfo] = useState<TableCellProps | null>(null);
    const [dialogTitle, setDialogTitle] = useState<string>("Devices");
    const [dialogNoDataContent, setDialogNoDataContent] = useState<string>("No Devices");
    const [searchValue, setSearchValue] = useState<string>("");
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [usersPerMonthChartData, setUsersPerMonthChartData] = useState<any[]>([]);
    const [usersGrowChartData, setUsersGrowChartData] = useState<any[]>([]);
    const [chartsWrapperHeight, setChartsWrapperHeight] = useState<number>(0);
    const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(false);
    const [viewMode, setViewMode] = useState<"grid" | "chart">("grid");
    const [showTunnelsInfoDialog, setShowTunnelsInfoDialog] = useState<boolean>(false);
    const [currentUserIdForTunnelsDialog, setCurrentUserIdForTunnelsDialog] = useState<string>("");
    const [editingConsumerRoleId, setEditingConsumerRoleId] = useState<string>("");
    const [allMethods, setAllMethods] = useState<IMethod[]>([]);
    const [allConsumerRolesAuthorities, setAllConsumerRolesAuthorities] = useState<{ [key: string]: string[] }>({});
    useEffect(() => {
        setIsDataLoading(true);
        getUsersWithStatistics(page, rowsPerPage, searchValue)
            .then((response) => {
                setIsDataLoading(false);
                const data = (response && response.data) || [];
                const usersWithDevicesAndTunnelsData = data && data.usersWithDevicesAndTunnels;
                setUsersWithStatistics(usersWithDevicesAndTunnelsData);
                setCount(data && data.count);
                if (usersWithDevicesAndTunnelsData) {
                    const allConsumerRolesData: any = {};
                    usersWithDevicesAndTunnelsData.forEach((u: any) => {
                        if (u && u.consumerRole && u.consumerRole._id && u.consumerRole.authorities) {
                            allConsumerRolesData[u.consumerRole._id] =
                                (u.consumerRole.authorities && [...u.consumerRole.authorities]) || [];
                        }
                    });
                    setAllConsumerRolesAuthorities(allConsumerRolesData);
                }
            })
            .catch((e) => {
                setIsDataLoading(false);
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    }, [page, rowsPerPage, searchValue]);
    useEffect(() => {
        let height = 550;
        if (window.innerWidth < 1280) {
            height = 300;
        }
        if (window.innerWidth < 376) {
            height = 250;
        }
        setChartsWrapperHeight(height || 0);
        getUsersPerMonth()
            .then((response) => {
                let data = (response && response.data) || [];
                let growData: any[] = [];
                if (data.length) {
                    data = data.sort((a: any, b: any) => a.year - b.year || a.month - b.month);
                    for (let i = 1; i < data.length; i++) {
                        if (data[i].month - 1 !== data[i - 1].month) {
                            if (data[i].year === data[i - 1].year) {
                                data.splice(i, 0, { count: 0, month: data[i].month - 1, year: data[i].year });
                                i--;
                            } else {
                                if (data[i].month !== 1) {
                                    data.splice(i, 0, { count: 0, month: data[i].month - 1, year: data[i].year });
                                    i--;
                                }
                            }
                        }
                    }
                    data = data.map((item: any) => {
                        return {
                            count: item.count,
                            month: `${String(item.month)}-${String(item.year).substring(2)}`,
                        };
                    });
                    growData = [...data];
                    growData.reduce(function (total, current) {
                        total += +current.count;
                        current.total = total;
                        return total;
                    }, 0);
                }
                setUsersPerMonthChartData(data);
                setUsersGrowChartData(growData);
            })
            .catch((e) => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    }, []);
    useEffect(() => {
        const handleResize = () => {
            let height = 550;
            if (window.innerWidth < 1280) {
                height = 300;
            }
            if (window.innerWidth < 376) {
                height = 250;
            }
            setChartsWrapperHeight(height || 0);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });
    useEffect(() => {
        getRolesAllMethods()
            .then((methods) => {
                setAllMethods(methods && methods.data);
            })
            .catch((e: any) => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    }, []);
    const searchValueChange = (value: string) => {
        setPage(0);
        setSearchValue(value);
    };
    const onChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
    };
    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
    };
    const onViewDevicesClick = (props: TableCellProps) => {
        setDialogInfo(props);
        setShowInfoDialog(true);
        setDialogTitle("Devices");
        setDialogNoDataContent("No Devices");
    };
    const onViewTunnelsClick = (props: TableCellProps) => {
        const id = props.rowData._id;
        if (id) {
            setCurrentUserIdForTunnelsDialog(id);
            setShowTunnelsInfoDialog(true);
        }
    };
    const onCloseInfoDialog = () => {
        setShowInfoDialog(false);
    };
    const onCloseTunnelsDialog = () => {
        setCurrentUserIdForTunnelsDialog("");
        setShowTunnelsInfoDialog(false);
    };
    const determineAvailability = (lastSeen: number) => {
        const newDate = Date.now();

        if (!lastSeen) {
            return "offline";
        }

        const diffMs = newDate - lastSeen;
        const diffMins = Math.round(diffMs / (1000 * 60));
        if (diffMins > 10) {
            return "offline";
        }
        if (diffMins > 3) {
            return "away";
        }
        return "online";
    };
    const devicesCellRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        const data = props.cellData;
        let onlineCount = 0;

        for (const device of data) {
            if (device.status) {
                try {
                    device.statusInfo = JSON.parse(device.status);
                    if (determineAvailability(device.statusInfo.lastSeen) === "online") {
                        onlineCount = onlineCount + 1;
                    }
                } catch (e) {}
            }
        }
        return (
            <div className={classes.devicesAndTunnelsWrapper}>
                <div>{props.cellData && props.cellData.length}</div>
                <div> Online ({onlineCount})</div>
                <IconButton className={classes.eyeButton} onClick={() => onViewDevicesClick(props)}>
                    <VisibilityIcon />
                </IconButton>
            </div>
        );
    };
    const tunnelsCellRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => (
        <div className={classes.devicesAndTunnelsWrapper}>
            <div>{props.cellData && props.cellData.length}</div>
            <IconButton className={classes.eyeButton} onClick={() => onViewTunnelsClick(props)}>
                <VisibilityIcon />
            </IconButton>
        </div>
    );

    const handleAuthoritiesChange = (
        e: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
        consumerRoleId: string
    ) => {
        const newConsumerRoles = { ...allConsumerRolesAuthorities };
        newConsumerRoles[consumerRoleId] = e.target.value as string[];
        setAllConsumerRolesAuthorities(newConsumerRoles);
    };

    const handleAuthoritiesSave = () => {
        const id = editingConsumerRoleId;
        setIsDataLoading(true);
        updateConsumerRole(id, { authorities: allConsumerRolesAuthorities[id] })
            .then((data) => {
                setIsDataLoading(false);
                const updatedConsumerRole: IConsumerRole = data && data.data;
                if (updatedConsumerRole) {
                    const newUsersWithStatistics = [...usersWithStatistics];
                    const userToBeUpdate = newUsersWithStatistics.find((u) => u._id === updatedConsumerRole.consumerId);
                    if (userToBeUpdate) {
                        const userToBeUpdateIndex = newUsersWithStatistics.indexOf(userToBeUpdate);
                        userToBeUpdate.consumerRole = updatedConsumerRole;
                        newUsersWithStatistics.splice(userToBeUpdateIndex, 1, userToBeUpdate);
                        setUsersWithStatistics(newUsersWithStatistics);
                    }
                }
            })
            .catch((e: any) => {
                setIsDataLoading(false);
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
        setEditingConsumerRoleId("");
    };
    const shouldUpdate = (id: string) => {
        const currentAuthoritiesData = allConsumerRolesAuthorities[id];
        const initialConsumerRole = usersWithStatistics.filter((u) => u.consumerRole && u.consumerRole._id === id)[0];
        const previousAuthorities =
            initialConsumerRole && initialConsumerRole.consumerRole && initialConsumerRole.consumerRole.authorities;
        if (!Array.isArray(currentAuthoritiesData) || !Array.isArray(previousAuthorities)) {
            return false;
        }
        if (currentAuthoritiesData.length !== previousAuthorities.length) {
            return true;
        }
        for (let i = 0; i < currentAuthoritiesData.length; i += 1) {
            if (previousAuthorities.indexOf(currentAuthoritiesData[i]) === -1) {
                return true;
            }
        }

        return false;
    };

    const gridConsumerRoleContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        const consumerRole = props.cellData;
        return (
            <div style={{ width: "100%" }}>
                {consumerRole ? (
                    <>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <div style={{ width: 140 }}>{`${
                                (allConsumerRolesAuthorities[consumerRole._id] || []).length
                            } methods allowed`}</div>
                            <IconButton
                                onClick={() => {
                                    setEditingConsumerRoleId(consumerRole._id);
                                }}
                            >
                                <ArrowDownIcon />
                            </IconButton>
                            <MultiSelectDialog
                                title="Methods"
                                isOpen={editingConsumerRoleId === consumerRole._id}
                                handleClose={() => {
                                    setEditingConsumerRoleId("");

                                    const oldAuthorities = usersWithStatistics.filter(
                                        (u) => u.consumerRole && u.consumerRole._id === consumerRole._id
                                    );
                                    const old =
                                        (oldAuthorities &&
                                            oldAuthorities[0] &&
                                            oldAuthorities[0].consumerRole &&
                                            oldAuthorities[0].consumerRole.authorities) ||
                                        [];
                                    const newData = { ...allConsumerRolesAuthorities };
                                    newData[consumerRole._id] = old;
                                    setAllConsumerRolesAuthorities(newData);
                                }}
                                handleSave={handleAuthoritiesSave}
                                data={allMethods}
                                getIsChecked={(method: IMethod) =>
                                    (allConsumerRolesAuthorities[consumerRole._id] || []).indexOf(method.name) > -1
                                }
                                primaryTextLabel="name"
                                secondaryTextLabel="serviceName"
                                handleToggle={(method: IMethod) => {
                                    const newData = { ...allConsumerRolesAuthorities };
                                    const currentIndex = newData[consumerRole._id].indexOf(method.name);
                                    if (currentIndex > -1) {
                                        newData[consumerRole._id].splice(currentIndex, 1);
                                    } else {
                                        newData[consumerRole._id].push(method.name);
                                    }
                                    setAllConsumerRolesAuthorities(newData);
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <div>No Consumer Role</div>
                )}
            </div>
        );
    };

    const gridEmailContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        const data = (
            <div style={{ display: "flex", alignItems: "center" }}>
                {props.rowData.emailVerified ? (
                    <CheckedIcon fontSize="small" htmlColor="green" />
                ) : (
                    <XIcon fontSize="small" htmlColor="red" />
                )}

                <div style={{ marginLeft: 10 }}>{props.cellData}</div>
            </div>
        );
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={props.cellData} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };

    const gridCreatedAtContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        const data = (
            <div className={classes.gridDefaultContextRenderer}>{new Date(props.cellData).toLocaleDateString()}</div>
        );
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={new Date(props.cellData).toLocaleDateString()} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };
    const gridDefaultContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        const data = <div className={classes.gridDefaultContextRenderer}>{props.cellData}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={props.cellData} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };
    const columns: IGridColumn[] = [
        {
            dataKey: "email",
            width: 200,
            label: "Email",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridEmailContextRenderer,
        },
        {
            dataKey: "createdAt",
            width: 70,
            label: "Created at",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridCreatedAtContextRenderer,
        },
        {
            dataKey: "fullName",
            width: 200,
            label: "Full Name",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "username",
            width: 200,
            label: "Username",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "consumerRole",
            width: 200,
            label: "Consumer Role Methods",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridConsumerRoleContextRenderer,
        },
        { dataKey: "devices", width: 200, label: "Devices", cellContentRenderer: devicesCellRenderer },
        { dataKey: "tunnels", width: 200, label: "Tunnels", cellContentRenderer: tunnelsCellRenderer },
    ];

    return (
        <ServiceMethodsContext.Provider value={allMethods}>
            <div className={classes.root}>
                <div className={classes.navigationButtons}>
                    <Fab
                        style={{ margin: 5 }}
                        className={classes.arrowRightIcon}
                        size="small"
                        onClick={() => setIsDrawerOpened(true)}
                    >
                        <ArrowRightIcon />
                    </Fab>
                    <Fab
                        className={classes.navigationButton}
                        variant={"extended"}
                        size="small"
                        onClick={() => setViewMode("chart")}
                    >
                        <ChartIcon />
                    </Fab>
                    <Fab
                        className={classes.navigationButton}
                        variant={"extended"}
                        size="small"
                        onClick={() => setViewMode("grid")}
                    >
                        <GridIcon />
                    </Fab>
                </div>
                <Drawer open={isDrawerOpened} onClose={() => setIsDrawerOpened(false)}>
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={() => setIsDrawerOpened(false)}
                        onKeyDown={() => setIsDrawerOpened(false)}
                    >
                        <div>
                            <List>
                                <ListItem button onClick={() => setViewMode("chart")}>
                                    <ListItemIcon>
                                        <ChartIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Chart view" />
                                </ListItem>
                                <ListItem button onClick={() => setViewMode("grid")}>
                                    <ListItemIcon>
                                        <GridIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Grid view" />
                                </ListItem>
                            </List>
                        </div>
                    </div>
                </Drawer>
                {viewMode === "chart" && (
                    <div className={classes.chartsWrapper}>
                        {!!usersPerMonthChartData.length && (
                            <UsersPerMonth
                                chartsWrapperHeight={chartsWrapperHeight}
                                chartData={usersPerMonthChartData}
                            />
                        )}
                        {!!usersGrowChartData.length && (
                            <UsersGrowth chartsWrapperHeight={chartsWrapperHeight} chartData={usersGrowChartData} />
                        )}
                    </div>
                )}
                {viewMode === "grid" && (
                    <div className={classes.gridWrapper}>
                        <Grid
                            data={usersWithStatistics}
                            columns={columns}
                            enablePagination
                            allDataCount={count}
                            onChangePage={onChangePage}
                            onChangeRowsPerPage={onChangeRowsPerPage}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            gridToolbarTitle="Users"
                            searchValueChange={searchValueChange}
                            searchValueChangeTimeOutForCall={1000}
                            isDataLoading={isDataLoading}
                            rowsPerPageOptions={[100, 250, 500]}
                            labelRowsPerPage={window.innerWidth > 992 ? undefined : null}
                        />
                        {showInfoDialog && (
                            <InfoDialog
                                data={dialogInfo}
                                title={dialogTitle}
                                onClose={onCloseInfoDialog}
                                noDataContent={dialogNoDataContent}
                            />
                        )}
                        {showTunnelsInfoDialog && (
                            <TunnelsInfoDialog onClose={onCloseTunnelsDialog} userId={currentUserIdForTunnelsDialog} />
                        )}
                    </div>
                )}
            </div>
        </ServiceMethodsContext.Provider>
    );
};

export default withStyles(styles)(Users);
