import React, { useEffect, useReducer } from "react";
import { Route, Switch } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import "./App.css";
import reducer, { initialState, State } from "./reducer";
import { getAuthToken, getLoggedInUser } from "./api";
import { Action } from "./interfaces/actions";
import Main from "./components/main";
import Login from "./components/login";
import { AppDispatch, UserContext } from "./context";
import WithAuthGuard from "./components/WithAuthenticationGuard";
import SnackbarContentWrapper from "./components/snackbar/snackbarContentWrapper";

const withAuthGuard = WithAuthGuard({ authenticatedGuard: true })(Main);
const login = WithAuthGuard({ authenticatedGuard: false })(Login);

function App() {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { loggedInUser, errorMessage, loadingInitialData } = state;

    useEffect(() => {
        (async () => {
            const authToken = getAuthToken();

            if (!authToken) {
                dispatch({ type: "INITIAL_DATA_READY" });
            } else {
                getLoggedInUser()
                    .then((response: any) => {
                        dispatch({ type: "LOGGED_IN_USER", payload: response.data });
                    })
                    .catch((e) => {
                        dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                    });
            }
        })();
    }, []);

    if (loadingInitialData) {
        return null;
    }

    const onErrorSnackBarClose = () => {
        dispatch({ type: "CLOSE_MESSAGE_ERROR" });
    };

    const errorSnackbar = (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={5000}
            open={errorMessage !== ""}
            onClose={onErrorSnackBarClose}
            // ContentProps={{
            //     "aria-describedby": "snackbar-error-message-id"
            // }}
        >
            <SnackbarContentWrapper onClose={onErrorSnackBarClose} variant="error" message={errorMessage} />
        </Snackbar>
    );

    return (
        <AppDispatch.Provider value={dispatch}>
            <UserContext.Provider value={loggedInUser}>
                <Switch>
                    <Route path="/login" component={login} />
                    <Route path="/" component={withAuthGuard} />
                </Switch>
                {errorSnackbar}
            </UserContext.Provider>
        </AppDispatch.Provider>
    );
}

export default App;
