import { BASE_DOMAIN } from "../constants/app";
import { makeGet, makePatch } from "./index";

const API_URL = {
    GET_EMAIL_DOMAINS: () => `${BASE_DOMAIN}/v1/email-domains`,
    UPDATE_DOMAIN: (domainId: string) => `${BASE_DOMAIN}/v1/email-domains/${domainId}`,
};

export function getEmailDomains() {
    return makeGet(API_URL.GET_EMAIL_DOMAINS(), {});
}

export function updateDomain(domainId: string, body: any) {
    return makePatch(API_URL.UPDATE_DOMAIN(domainId), body, {});
}
