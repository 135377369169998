import React, { useContext, useEffect, useState } from "react";
import AuthoritiesTable from "./authoritiesTable";
import { IMethodWithRoles } from "../../interfaces/authorities";
import { getAllMethodsWithRoles } from "../../api/methods";
import { IRole } from "../../interfaces/roles";
import { getRoles, updateRole } from "../../api/roles";
import { AppDispatch } from "../../context";

const Authorities = () => {
    const [allMethodsWithRoles, setAllMethodsWithRoles] = useState<IMethodWithRoles[]>([]);
    const [allRoles, setAllRoles] = useState<IRole[]>([]);
    const dispatch = useContext(AppDispatch)!;

    useEffect(() => {
        getAllMethodsWithRoles()
            .then((response) => {
                const data = (response && response.data) || [];
                setAllMethodsWithRoles(data);
            })
            .catch((e) => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
        getRoles()
            .then((response) => {
                const roles = (response && response.data) || [];
                const data = roles.map((r: any) => {
                    return { id: r._id, name: r.name, authorities: r.authorities };
                });
                setAllRoles(data);
            })
            .catch((e) => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    }, []);

    const updateRoles = (
        updateData: { rolesToBeRemoved: string[]; rolesToBeAdded: string[] },
        authorityName: string
    ) => {
        const allRolesTobeUpdate: string[] = [...updateData.rolesToBeRemoved, ...updateData.rolesToBeAdded];
        Promise.all(
            allRolesTobeUpdate.map((roleName) => {
                const role = allRoles.filter((role) => role.name === roleName)[0];
                if (!role) return;
                if (updateData.rolesToBeRemoved.indexOf(roleName) > -1) {
                    // authority must be removed from that role
                    // TODO why can't use let or const instead off var
                    var authorities = role.authorities;
                    authorities = authorities.slice(0);
                    const index = authorities.indexOf(authorityName);
                    authorities.splice(index, 1);
                    return updateRole(role.id, { authorities });
                } else {
                    // authority must be added to that role
                    var authorities = role.authorities;
                    authorities = authorities.slice(0);
                    authorities.push(authorityName);
                    return updateRole(role.id, { authorities });
                }
            })
        )
            .then((response: any[]) => {
                for (let i = 0; i < response.length; i++) {
                    updateAllRoles(response[i] && response[i].data);
                }
            })
            .catch((e: any) => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    };

    function updateAllRoles(role: any) {
        const newRole: IRole = {
            id: role._id,
            name: role.name,
            authorities: role.authorities,
            access: role.access,
            allOrganization: role.allOrganization,
            uiConfig: role.uiConfig,
            organizationIds: role.organizationIds,
        };
        const oldRole = allRoles.filter((r) => r.id === newRole.id)[0];
        const index: number = allRoles.indexOf(oldRole);
        const newRoles = [...allRoles];
        newRoles.splice(index, 1, newRole);
        setAllRoles(newRoles);
    }

    return <AuthoritiesTable updateRoles={updateRoles} allRoles={allRoles} tableData={allMethodsWithRoles} />;
};

export default Authorities;
