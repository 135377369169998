import React from "react";
import { Axis, DomainItems, FactoryFn, ModifyDomainFn, NumberArray, ScaleObject } from "@devexpress/dx-react-chart";

export const labelComponent: React.ComponentType<Axis.LabelProps> = props => {
    return (
        <text
            dominantBaseline={props.dominantBaseline}
            textAnchor={props.textAnchor}
            x={props.x}
            y={props.y}
            style={{
                fill: "rgba(0, 0, 0, 0.54)",
                fontSize: "12px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 600,
                backgroundColor: "#fff"
            }}
            transform={`translate(${props.x - 15}, ${Math.sqrt(props.x * props.x + props.y * props.y) +
                20}) rotate(-90)`}
        >
            {props.text}
        </text>
    );
};

export const modifyDomain: ModifyDomainFn = (domain: DomainItems) => {
    const start = domain[0];
    const end = domain[1];
    return [start, end];
};

export const factory: FactoryFn = function() {
    const scale: ScaleObject = function(value: any) {
        return 1;
    };
    scale.range = function(range?: NumberArray): any {
        debugger;
        if (range === undefined) {
            return [1, 2, 3, 4, 5];
        }
        return this;
    };
    scale.domain = function(domain?: DomainItems): any {
        debugger;
        if (domain === undefined) {
            return [1, 2, 3, 4, 5];
        }
        return this;
    };
    scale.copy = function() {
        debugger;
        return this;
    };
    return scale;
};
