import React, { useEffect, useState } from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import MuiVirtualizedTable from "./wrappedVirtualizedTable";
import { RowMouseEventHandlerParams, ColumnProps, TableCellProps } from "react-virtualized";
import TablePagination from "@material-ui/core/TablePagination";
import { TablePaginationActions } from "./paginationActions";
import GridToolbar from "./gridToolbar";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = () =>
    createStyles({
        pagination: {
            position: "absolute",
            right: 10,
            bottom: 10,
            padding: 0,
            border: 0,
        },
        loaderWrapper: {
            display: "flex",
            justifyContent: "center",
            padding: "30px",
        },
    });

interface IGridProps {
    data: any[];
    isDataLoading: boolean;
    columns: IGridColumn[];
    enablePagination?: boolean;
    rowsPerPageOptions?: number[];
    allDataCount?: number;
    rowsPerPage?: number;
    onChangeRowsPerPage?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    page?: number;
    onChangePage?: (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    gridToolbarTitle?: string | undefined;
    searchValueChange?: (value: string) => void;
    searchValueChangeTimeOutForCall?: number;
    labelRowsPerPage?: string | null;
    showCheckBoxes?: boolean;
}

export interface IGridColumn extends ColumnProps {
    flexGrow?: number;
    label: string;
    dataKey: string;
    cellContentRenderer?: (cellRendererProps: TableCellProps) => React.ReactNode;
    numeric?: boolean;
}

const Grid = ({
    classes,
    data,
    isDataLoading,
    columns,
    allDataCount = 0,
    enablePagination,
    rowsPerPage = 0,
    rowsPerPageOptions,
    page = 0,
    onChangePage = () => {},
    onChangeRowsPerPage,
    gridToolbarTitle,
    searchValueChange,
    searchValueChangeTimeOutForCall = 1000,
    labelRowsPerPage = "Rows Per Page",
    showCheckBoxes = false,
}: WithStyles<typeof styles> & IGridProps) => {
    const [selected, setSelected] = useState<number[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [timerId, setTimerId] = useState<any>(null);
    const [tableRowHeight, setTableRowHeight] = useState<number>(0);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setTableRowHeight(35);
            } else {
                setTableRowHeight(50);
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });
    useEffect(() => {
        if (window.innerWidth < 768) {
            setTableRowHeight(30);
        } else {
            setTableRowHeight(50);
        }
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, []);
    const checkboxCellRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => (
        <Checkbox
            checked={selected.indexOf(props.rowIndex) > -1}
            onChange={() => selectRow(props)}
            value="checkedB"
            color="primary"
        />
    );
    const firstColumn: IGridColumn = {
        dataKey: "checkboxes",
        width: 100,
        label: "",
        cellContentRenderer: checkboxCellRenderer,
    };
    const rowGetter = ({ index }: { index: number }) => data[index];
    const rowClick = (info: RowMouseEventHandlerParams) => {};
    function selectRow(props: TableCellProps) {
        const { rowIndex } = props;
        const selectedIndex = selected.indexOf(rowIndex);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, rowIndex);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    }
    const paddingBottom = enablePagination ? "60px" : 0;
    const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setSearchValue(val);
        clearTimeout(timerId);
        const tId = setTimeout(function () {
            searchValueChange!(val.toLowerCase());
        }, searchValueChangeTimeOutForCall);
        setTimerId(tId);
    };

    return (
        <Paper
            style={{ height: "calc(100% - 124px)", width: "100%", paddingBottom: paddingBottom, paddingTop: "64px" }}
        >
            <GridToolbar
                numSelected={selected.length}
                title={gridToolbarTitle}
                searchValue={searchValue}
                onSearchValueChange={onSearchValueChange}
            />
            {isDataLoading ? (
                <div className={classes.loaderWrapper}>
                    <CircularProgress size={50} />
                </div>
            ) : (
                <MuiVirtualizedTable
                    rowHeight={tableRowHeight}
                    headerHeight={tableRowHeight}
                    rowCount={data.length}
                    rowGetter={rowGetter}
                    onRowClick={rowClick}
                    columns={showCheckBoxes ? [firstColumn, ...columns] : columns}
                />
            )}
            {enablePagination && (
                <table>
                    <tbody>
                        <tr>
                            <TablePagination
                                labelRowsPerPage={labelRowsPerPage}
                                className={classes.pagination}
                                rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
                                colSpan={3}
                                count={allDataCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                onChangePage={onChangePage}
                                ActionsComponent={TablePaginationActions as any}
                            />
                        </tr>
                    </tbody>
                </table>
            )}
        </Paper>
    );
};

export default withStyles(styles)(Grid);
