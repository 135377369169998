export const ROUTES_MAP = [
    "/message-explorer",
    "/emails",
    "/email-domains",
    "/tunnel-engines",
    "/users",
    "/authorities",
    "/roles",
    "/packages",
    "/clientVersions",
    "/orgs",
    "/devicePackage",
    "/coupons",
];
