import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import createStyles from "@material-ui/core/styles/createStyles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Checkbox, ListItemText, List, ListItemIcon, ListItem, TextField } from "@material-ui/core";

const styles = () =>
    createStyles({
        dialogContent: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        list: {
            minHeight: "60vh",
            maxHeight: "60vh",
        },
    });

const MultiSelectDialog = ({
    classes,
    isOpen,
    handleClose,
    handleSave,
    data,
    primaryTextLabel,
    secondaryTextLabel,
    getIsChecked,
    handleToggle,
    title,
    withSearch = true,
    oneActionButton = false,
}: {
    classes: Partial<ClassNameMap<string>>;
    isOpen: boolean;
    handleClose: () => void;
    handleSave: () => void;
    data: any[];
    primaryTextLabel: string;
    secondaryTextLabel?: string;
    getIsChecked: (d: any) => boolean;
    handleToggle: (d: any) => void;
    title: string;
    withSearch?: boolean;
    oneActionButton?: boolean;
}) => {
    const [search, setSearch] = useState<string>("");

    const isVisible = (item: any) => {
        if (!search || !search.trim()) {
            return true;
        }
        if (
            (item[primaryTextLabel] && item[primaryTextLabel].toLowerCase().includes(search.trim().toLowerCase())) ||
            (secondaryTextLabel &&
                item[secondaryTextLabel] &&
                item[secondaryTextLabel].toLowerCase().includes(search.trim().toLowerCase()))
        ) {
            return true;
        }
        return false;
    };
    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                setSearch("");
                handleClose();
            }}
            aria-labelledby="add-role"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="add-role">{title}</DialogTitle>
            <DialogContent className={classes.dialogContent} style={{ overflow: "hidden" }}>
                {withSearch && (
                    <TextField
                        fullWidth
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                    />
                )}
                <List style={{ overflowY: "auto", width: "100%" }} className={classes.list}>
                    {data.map((d, i) => {
                        if (!withSearch || isVisible(d)) {
                            return (
                                <ListItem key={i} role={undefined} onClick={(e) => handleToggle(d)} dense button>
                                    <ListItemIcon>
                                        <Checkbox disableRipple edge="start" checked={getIsChecked(d)} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={d[primaryTextLabel]}
                                        secondary={!!secondaryTextLabel && d[secondaryTextLabel]}
                                    />
                                </ListItem>
                            );
                        } else {
                            return <React.Fragment key={i}></React.Fragment>;
                        }
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                {!oneActionButton && (
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                )}
                <Button
                    onClick={() => {
                        setSearch("");
                        handleSave();
                    }}
                    color="primary"
                    autoFocus
                >
                    {oneActionButton ? "Ok" : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(MultiSelectDialog);
