import React, { useContext, useEffect, useState } from "react";
import { IUser, IConsumerRole } from "../../interfaces/users";
import { getCouponCodes, addCouponCode } from "../../api/coupon-code";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { withStyles, createStyles, WithStyles, Theme, Tooltip, IconButton, FormControl } from "@material-ui/core";
import { AppDispatch } from "../../context";
import Grid, { IGridColumn } from "../grid";
import { TableCellProps } from "react-virtualized";
import AddCouponCodeDialog from "./addCouponCodeDialog";
import AddIcon from "@material-ui/icons/Add";
import { dispatch } from "d3";
export interface ICouponCode {
    name: string;
    description: string;
    /** This is the owner of the Device {User} */
    createdBy?: string | IUser;
    /** determine is user launch script on device or not */
    registered?: boolean;
    registeredAt?: Date;
    registeredBy?: string | IUser;

    code: string;
    duration: number;
    pricingPlan?: string | any;
}
const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flex: 1,
            height: "calc(100vh - 72px)",
            flexDirection: "column",
        },
        chartsWrapper: {
            display: "flex",
            flex: 3,
            flexDirection: "row",
            overflow: "auto",
            [theme.breakpoints.down("md")]: {
                flexDirection: "column",
            },
        },
        gridWrapper: {
            minHeight: 300,
            flex: 4,
        },
        chart: {
            flex: 1,
        },
        arrowRightIcon: {
            [theme.breakpoints.down("sm")]: {
                position: "absolute",
                bottom: 0,
                zIndex: 9,
            },
        },
        navigationButtons: {
            display: "flex",
            flexDirection: "column",
        },
        navigationButton: {
            margin: 5,
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
        eyeButton: {
            marginLeft: 10,
            padding: 8,
            [theme.breakpoints.down("sm")]: {
                padding: 2,
            },
        },
        devicesAndTunnelsWrapper: {
            minWidth: 55,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",
        },
        gridDefaultContextRenderer: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    });

const CouponCode = ({ classes }: WithStyles<typeof styles> & {}) => {
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(100);
    const [count, setCount] = useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
    const [couponCodes, setCouponCodes] = useState<ICouponCode[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const dispatch = useContext(AppDispatch)!;
    const addCouponCodeHandler = async (data: ICouponCode) => {
        // addCouponCod;
        try {
            setIsDataLoading(true);
            const res = await addCouponCode(data);
            setCount(count + 1);
            setCouponCodes([res.data, ...(couponCodes || [])]);
            setShowAddDialog(false);
        } catch (error) {
            dispatch({ type: "SHOW_MESSAGE_ERROR", payload: error });
        } finally {
            setIsDataLoading(false);
        }
    };
    useEffect(() => {
        setIsDataLoading(true);
        (async () => {
            try {
                const data = await getCouponCodes(page, rowsPerPage, searchValue);
                setCouponCodes(data.data.couponCodes);
                setCount(data.data.count);
            } catch (error) {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: error });
            } finally {
                setIsDataLoading(false);
            }
        })();
    }, [page, rowsPerPage, searchValue]);

    const searchValueChange = (value: string) => {
        setPage(0);
        setSearchValue(value);
    };
    const onChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPage(page);
    };
    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
    };

    const gridEmailContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        const data = (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginLeft: 10 }}>{props.cellData}</div>
            </div>
        );
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={props.cellData} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };

    const gridCreatedAtContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        const data = (
            <div className={classes.gridDefaultContextRenderer}>
                {props.cellData ? new Date(props.cellData).toLocaleDateString() : null}
            </div>
        );
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={new Date(props.cellData).toLocaleDateString()} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };
    const gridDefaultContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        const data = <div className={classes.gridDefaultContextRenderer}>{props.cellData}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={props.cellData} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };
    const columns: IGridColumn[] = [
        {
            dataKey: "name",
            width: 200,
            label: "Name",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "description",
            width: 200,
            label: "Description",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "createdAt",
            width: 200,
            label: "Created at",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridCreatedAtContextRenderer,
        },

        {
            dataKey: "code",
            width: 200,
            label: "Code",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "registered",
            width: 100,
            label: "Registered",
            cellContentRenderer: gridEmailContextRenderer,
        },
        {
            dataKey: "registeredAt",
            width: 200,
            label: "registeredAt",
            cellContentRenderer: gridCreatedAtContextRenderer,
        },
        {
            dataKey: "duration",
            width: 200,
            label: "duration",
            cellContentRenderer: gridCreatedAtContextRenderer,
        },
        {
            dataKey: "pricingPlan",
            width: 200,
            label: "pricingPlan",
            cellContentRenderer: gridDefaultContextRenderer,
        },
    ];

    return (
        <div className={classes.root}>
            <div style={{ margin: "auto" }}>
                <FormControl>
                    <label htmlFor="contained-button-file">
                        <IconButton
                            onClick={() => {
                                setShowAddDialog(true);
                            }}
                        >
                            Add Coupon Code <AddIcon />
                        </IconButton>
                    </label>
                </FormControl>
            </div>
            <div className={classes.gridWrapper}>
                <Grid
                    data={couponCodes || []}
                    columns={columns}
                    enablePagination
                    allDataCount={count}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    gridToolbarTitle="Users"
                    searchValueChange={searchValueChange}
                    searchValueChangeTimeOutForCall={1000}
                    isDataLoading={isDataLoading}
                    rowsPerPageOptions={[100, 250, 500]}
                    labelRowsPerPage={window.innerWidth > 992 ? undefined : null}
                />
            </div>
            {showAddDialog && (
                <AddCouponCodeDialog
                    addCouponCodeHandler={addCouponCodeHandler}
                    onClose={() => setShowAddDialog(false)}
                />
            )}
        </div>
    );
};

export default withStyles(styles)(CouponCode);
