import { BASE_DOMAIN } from "../constants/app";
import { makePatch } from "./index";

const API_URL = {
    UPDATE_CONSUMER_ROLE: (consumerRoleId: string) => `${BASE_DOMAIN}/v1/consumer-role/${consumerRoleId}`,
};

export function updateConsumerRole(consumerRoleId: string, body: any) {
    return makePatch(API_URL.UPDATE_CONSUMER_ROLE(consumerRoleId), body, {});
}
