import React, { useState, useEffect } from "react";
import { Dialog, TextField, withStyles, WithStyles, DialogActions } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, Button } from "@material-ui/core";
import semver from "semver";
import { IPackage } from "../../interfaces";

const styles = createStyles({
    card: {
        width: 300,
        height: 300
    },
    textFieldsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    nextButton: {
        float: "right"
    },
    selectFormControl: {
        margin: "8px 0"
    }
});
const AddPackageDialog = ({
    classes,
    isOpen,
    onClose,
    onSave,
    packageInfo
}: WithStyles<typeof styles> & {
    isOpen: boolean;
    onClose: () => void;
    onSave: (data: Partial<IPackage>) => void;
    packageInfo: Partial<IPackage>;
}) => {
    const validateSemver = (version: string) => {
        return semver.valid(version) === null ? false : true;
    };
    const [name, setName] = useState<string>(packageInfo.name || "");
    const [description, setDescription] = useState<string>(packageInfo.description || "");
    const [version, setVersion] = useState<string>(packageInfo.version || "");
    const [accessPermissions, setAccessPermissions] = useState<string>(packageInfo.accessPermissions || "");
    const [postInstall, setPostInstall] = useState<string>(packageInfo.accessPermissions || "");
    const [preDelete, setPreDelete] = useState<string>(packageInfo.preDelete || "");

    useEffect(() => {
        if (packageInfo) {
            setName(packageInfo.name || "");
            setDescription(packageInfo.description || "");
            setVersion(packageInfo.version || "");
            setPostInstall(packageInfo.postInstall || "");
            setAccessPermissions(packageInfo.accessPermissions || "");
            setPreDelete(packageInfo.preDelete || "");
        }
    }, [packageInfo]);
    const [isValidSemVersion, setIsValidSemVersion] = useState<boolean>(true);
    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (name) {
            case "name":
                setName(event.target.value);
                break;
            case "description":
                setDescription(event.target.value);
                break;
            case "version":
                setVersion(event.target.value);
                setIsValidSemVersion(validateSemver(event.target.value));
                break;
            case "access_permissions":
                setAccessPermissions(event.target.value);
                break;
            case "post_install":
                setPostInstall(event.target.value);
                break;
            case "pre_delete":
                setPreDelete(event.target.value);
                break;
        }
    };
    const onCreateBtnClick = () => {
        onSave({ name, description, version, _id: packageInfo._id, accessPermissions, postInstall, preDelete });
    };
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Add Package</DialogTitle>
            <DialogContent>
                <div className={classes.card}>
                    <div className={classes.textFieldsWrapper}>
                        <TextField
                            id="standard-name"
                            label="Name"
                            value={name}
                            onChange={handleChange("name")}
                            margin="normal"
                        />
                        <TextField
                            id="standard-multiline-flexible"
                            label="Description"
                            multiline
                            rowsMax="4"
                            value={description}
                            onChange={handleChange("description")}
                            margin="normal"
                        />
                        <TextField
                            error={!isValidSemVersion}
                            label="Version"
                            value={version}
                            onChange={handleChange("version")}
                            margin="normal"
                        />
                        <TextField
                            label="Access Permissions"
                            value={accessPermissions}
                            onChange={handleChange("access_permissions")}
                            margin="normal"
                        />
                        <TextField
                            label="Post Install"
                            value={postInstall}
                            onChange={handleChange("post_install")}
                            margin="normal"
                        />
                        <TextField
                            label="Pre Delete"
                            value={preDelete}
                            onChange={handleChange("pre_delete")}
                            margin="normal"
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button className={classes.nextButton} onClick={onClose}>
                    Cancel
                </Button>
                <Button className={classes.nextButton} onClick={onCreateBtnClick}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default withStyles(styles)(AddPackageDialog);
