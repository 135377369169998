import { constractGlobalErrorMessage, parseErrorMessage } from "../utils/globalMessageUtil";
import { Action } from "../interfaces/actions";

export interface State {
    errorMessage: string;
    successMessage: string;
    loggedInUser: any;
    loadingInitialData: boolean;
}

export const initialState: State = {
    errorMessage: "",
    successMessage: "",
    loggedInUser: null,
    loadingInitialData: true
};

export default function reducer(state: State, action: Action) {
    switch (action.type) {
        case "INIT": {
            return {
                ...state,
                loadingInitialData: true
            };
        }
        case "INITIAL_DATA_READY": {
            return {
                ...state,
                ...action.payload,
                loadingInitialData: false
            };
        }
        case "LOGOUT": {
            return {
                ...state,
                loggedInUser: null
            };
        }
        case "SHOW_MESSAGE_SUCCESS": {
            return {
                ...state,
                successMessage: action.payload,
                errorMessage: ""
            };
        }
        case "SHOW_MESSAGE_ERROR": {
            return {
                ...state,
                errorMessage: constractGlobalErrorMessage(parseErrorMessage(action.payload)),
                successMessage: ""
            };
        }
        case "CLOSE_MESSAGE_SUCCESS": {
            return {
                ...state,
                successMessage: ""
            };
        }
        case "CLOSE_MESSAGE_ERROR": {
            return {
                ...state,
                errorMessage: ""
            };
        }
        case "LOGGED_IN_USER": {
            return {
                ...state,
                loggedInUser: action.payload,
                loadingInitialData: false
            };
        }

        default:
            return state;
    }
}
