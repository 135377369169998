import React, { useState, useEffect } from "react";
import { Dialog, TextField, withStyles, WithStyles, DialogActions } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, Button } from "@material-ui/core";
import semver from "semver";

import { ITunnelEngine } from "../../interfaces/users";

const styles = createStyles({
    card: {
        width: 500,
        height: 500,
    },
    textFieldsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    },
    nextButton: {
        float: "right",
    },
    selectFormControl: {
        margin: "8px 0",
    },
});
const EditTunnelEnginDialog = ({
    classes,
    isOpen,
    onClose,
    onSave,
    tunnelEngin,
}: WithStyles<typeof styles> & {
    isOpen: boolean;
    onClose: () => void;
    onSave: (id: string, data: Partial<ITunnelEngine>) => void;
    tunnelEngin: Partial<ITunnelEngine>;
}) => {
    const validateSemver = (version: string) => {
        return semver.valid(version) === null ? false : true;
    };
    const [name, setName] = useState<string>(tunnelEngin.name || "");
    const [address, setAddress] = useState<string>(tunnelEngin.address || "");
    const [privateIpAddress, setPrivateIpAddress] = useState<string>(tunnelEngin.privateIpAddress || "");
    const [publicIpAddress, setPublicIpAddress] = useState<string>(tunnelEngin.publicIpAddress || "");
    const [addressType, setAddressType] = useState<string>(tunnelEngin.addressType || "");
    const [status, setStatus] = useState<string>(tunnelEngin.status || "");

    useEffect(() => {
        if (tunnelEngin) {
            setName(tunnelEngin.name || "");
            setAddress(tunnelEngin.address || "");
            setPrivateIpAddress(tunnelEngin.privateIpAddress || "");
            setAddressType(tunnelEngin.addressType || "");
            setPublicIpAddress(tunnelEngin.publicIpAddress || "");
            setStatus(tunnelEngin.status || "");
        }
    }, [tunnelEngin]);
    const [isValidSemVersion, setIsValidSemVersion] = useState<boolean>(true);
    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (name) {
            case "name":
                setName(event.target.value);
                break;
            case "address":
                setAddress(event.target.value);
                break;
            case "privateIpAddress":
                setPrivateIpAddress(event.target.value);
                setIsValidSemVersion(validateSemver(event.target.value));
                break;
            case "publicIpAddress":
                setPublicIpAddress(event.target.value);
                break;
            case "addressType":
                setAddressType(event.target.value);
                break;
            case "status":
                setStatus(event.target.value);
                break;
        }
    };
    const onCreateBtnClick = () => {
        tunnelEngin._id &&
            onSave(tunnelEngin._id, {
                name,
                address,
                addressType,
                publicIpAddress,
                privateIpAddress,
            });
    };
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Add Package</DialogTitle>
            <DialogContent>
                <div className={classes.card}>
                    <div className={classes.textFieldsWrapper}>
                        <TextField
                            id="standard-name"
                            label="Name"
                            value={name}
                            onChange={handleChange("name")}
                            margin="normal"
                        />
                        <TextField label="Address" value={address} onChange={handleChange("address")} margin="normal" />
                        <TextField
                            label="Private Ip Address"
                            value={privateIpAddress}
                            onChange={handleChange("privateIpAddress")}
                            margin="normal"
                        />
                        <TextField
                            label="Public Ip Address"
                            value={publicIpAddress}
                            onChange={handleChange("publicIpAddress")}
                            margin="normal"
                        />
                        <TextField
                            label="Address Type"
                            value={addressType}
                            onChange={handleChange("addressType")}
                            margin="normal"
                        />
                        <TextField label="Status" value={status} onChange={handleChange("status")} margin="normal" />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button className={classes.nextButton} onClick={onCreateBtnClick}>
                    Update
                </Button>
                <Button className={classes.nextButton} onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default withStyles(styles)(EditTunnelEnginDialog);
