import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

import {
    createStyles,
    Dialog,
    withStyles,
    WithStyles,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    IconButton,
} from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button/Button";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import { bytesToSize } from "../../utils/helpers";
import { getUserTunnelsWithDevicesAndPorts, reloadTunnel } from "../../api/users";
import { AppDispatch } from "../../context";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
const styles = () =>
    createStyles({
        dialog: {
            "@media (min-width: 600px)": {
                // height: "375px",
                minWidth: "350px",
            },
        },
        dialogContent: {
            padding: 0,
            minHeight: "215px",
            // height: "215px",
            borderTop: "1px solid rgba(80, 76, 76, 0.14)",
        },
        dialogActions: {
            padding: "13px 20px",
            margin: 0,
        },
        emptyPage: {
            padding: "12px 24px",
        },
        nav: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        listItem: {
            paddingLeft: 24,
            paddingRight: 24,
            borderBottom: "1px solid rgba(80, 76, 76, 0.14)",
        },
        listItemText: {
            "&>span": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
        },
        listItemDeleted: {
            backgroundColor: "rgba(0, 0, 0, 0.14)",
        },
        loaderWrapper: {
            display: "flex",
            justifyContent: "center",
            padding: "30px",
        },
    });

interface IInfoDialogProps {
    onClose: () => void;
    userId: string;
}

const InfoDialog = ({
    classes,
    onClose,
    userId,
    fullScreen,
}: WithStyles<typeof styles> &
    IInfoDialogProps & {
        fullScreen?: boolean;
    }) => {
    const dispatch = useContext(AppDispatch)!;
    const [data, setData] = useState<any[]>([]);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsDataLoading(true);
        getUserTunnelsWithDevicesAndPorts(userId)
            .then((data) => {
                if (data && Array.isArray(data.data)) {
                    setData(data.data);
                }
                setIsDataLoading(false);
            })
            .catch((e) => {
                setIsDataLoading(false);
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    }, []);
    const reloadTunnelHandler = (id: string) => async () => {
        try {
            await reloadTunnel(id);
        } catch (e) {
            alert("Error");
        }
    };
    return (
        <Dialog
            fullWidth
            maxWidth="xl"
            open
            classes={{
                paper: classes.dialog,
            }}
            fullScreen={fullScreen !== undefined ? fullScreen : true}
            onClose={onClose}
        >
            <DialogTitle>Tunnels</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {isDataLoading ? (
                    <>
                        <div className={classes.loaderWrapper}>
                            <CircularProgress size={50} />
                        </div>
                    </>
                ) : (
                    <>
                        {!data.length ? (
                            <div className={classes.emptyPage}>No tunnels</div>
                        ) : (
                            <>
                                <Table stickyHeader style={{ minWidth: 600 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Local port</TableCell>
                                            <TableCell>Client port</TableCell>
                                            <TableCell>Device port</TableCell>
                                            <TableCell>Start Time</TableCell>
                                            <TableCell>Stop Time</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Data transferred</TableCell>
                                            <TableCell>Device</TableCell>
                                            <TableCell>Device Status</TableCell>
                                            <TableCell>Reload</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((item: any) => {
                                            const { localPort } = item;
                                            let deviceName = "";
                                            let clientPort = "";
                                            let devicePort = "";

                                            const ports = item.ports || [];
                                            ports.forEach((port: any) => {
                                                if (port.type === item.type) {
                                                    clientPort = port.port;
                                                } else {
                                                    devicePort = port.port;
                                                }
                                            });
                                            const determineAvailability = (lastSeen: number) => {
                                                const newDate = Date.now();

                                                if (!lastSeen) {
                                                    return "Offline";
                                                }

                                                const diffMs = newDate - lastSeen;
                                                const diffMins = Math.round(diffMs / (1000 * 60));
                                                if (diffMins > 10) {
                                                    return "Offline";
                                                }
                                                if (diffMins > 3) {
                                                    return "Away";
                                                }
                                                return "Online";
                                            };
                                            let deviceStatus = "Offline";
                                            let tunnelUsageData = "";
                                            let startTime = 0;
                                            let stopTime = 0;
                                            let status = "";
                                            if (item.devices && item.devices[0]) {
                                                const device = item.devices[0];
                                                if (device) {
                                                    deviceName = device.name;
                                                    let statusInfo = null;
                                                    try {
                                                        statusInfo = JSON.parse(device.status);
                                                    } catch (e) {}
                                                    deviceStatus = determineAvailability(statusInfo?.lastSeen);
                                                }
                                                const { rx_bytes, tx_bytes } = (item &&
                                                    item.info &&
                                                    item.info.networks &&
                                                    item.info.networks.eth0) || {
                                                    rx_bytes: undefined,
                                                    tx_bytes: undefined,
                                                };
                                                startTime = item.startTime;
                                                stopTime = item.stopTime;
                                                status = item.status;
                                                if (
                                                    (Number(rx_bytes) || rx_bytes === 0) &&
                                                    (Number(tx_bytes) || tx_bytes === 0)
                                                ) {
                                                    tunnelUsageData = bytesToSize(Number(rx_bytes) + Number(tx_bytes));
                                                }
                                            }

                                            return (
                                                <TableRow
                                                    key={item._id}
                                                    style={item.deleted ? { backgroundColor: "grey" } : {}}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {item.name}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {localPort}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {clientPort}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {devicePort}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {!!startTime && moment(startTime).fromNow()}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {!!stopTime && moment(stopTime).fromNow()}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {status}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {tunnelUsageData || ""}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {deviceName}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <CircleIcon
                                                                fontSize="small"
                                                                style={{
                                                                    color:
                                                                        deviceStatus === "Online"
                                                                            ? "#61bd4f"
                                                                            : deviceStatus === "Away"
                                                                            ? "#f2d600"
                                                                            : "#eb5a46",
                                                                }}
                                                            />
                                                            {deviceStatus}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <IconButton
                                                            disabled={deviceStatus === "offline"}
                                                            onClick={reloadTunnelHandler(item._id)}
                                                        >
                                                            <RefreshIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withMobileDialog<IInfoDialogProps>()(withStyles(styles)(InfoDialog));
