import React, { useContext, useState } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import AddRoleModal from "./addRoleDialog";
import { AppDispatch } from "../../context";
import { IOrganization } from "../../interfaces";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === "light"
                ? {
                      color: theme.palette.secondary.main,
                      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.secondary.dark,
                  },
        spacer: {
            flex: "1 1 100%",
        },
        actions: {
            color: theme.palette.text.secondary,
        },
        title: {
            flex: "0 0 auto",
        },
        actionsWrapper: {
            display: "flex",
            flexDirection: "row",
        },
    });

const RolesTableToolbar = ({
    classes,
    numSelected,
    addRole,
    deleteRoles,
    allOrganizations,
}: {
    classes: Partial<ClassNameMap<string>>;
    numSelected: number;
    addRole: (data: any) => Promise<any>;
    deleteRoles: () => void;
    allOrganizations: IOrganization[];
}) => {
    const [addModalOpened, setAddModalOpened] = useState<boolean>(false);
    const [addRoleError, setAddRoleError] = useState<string>("");
    const dispatch = useContext(AppDispatch)!;

    const onAddRoleBtnClick = () => {
        setAddModalOpened(true);
    };

    const handleAddRoleDialogClose = () => {
        setAddModalOpened(false);
    };

    const handleAddRole = (data: any) => {
        addRole(data)
            .then(() => {
                setAddModalOpened(false);
            })
            .catch((e: any) => {
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                setAddRoleError("Something went wrong");
            });
    };

    return (
        <>
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight as string]: numSelected > 0,
                })}
            >
                <div className={classes.title}>
                    {numSelected > 0 ? (
                        <Typography color="inherit" variant="subtitle1">
                            {numSelected} selected
                        </Typography>
                    ) : (
                        <Typography variant="h6" id="tableTitle">
                            Authorities
                        </Typography>
                    )}
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    {numSelected > 0 ? (
                        <Tooltip title="Delete">
                            <IconButton aria-label="Delete" onClick={deleteRoles}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <div className={classes.actionsWrapper}>
                            <Tooltip title="Add role">
                                <IconButton aria-label="Add role" onClick={onAddRoleBtnClick}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Filter list">
                                <IconButton aria-label="Filter list">
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </Toolbar>
            {addModalOpened && (
                <AddRoleModal
                    handleAdd={handleAddRole}
                    handleClose={handleAddRoleDialogClose}
                    isOpen={addModalOpened}
                    addRoleError={addRoleError}
                    allOrganizations={allOrganizations}
                />
            )}
        </>
    );
};

export default withStyles(styles)(RolesTableToolbar);
