import React, { useState, useEffect, useContext } from "react";
import { WithStyles, withStyles, Tooltip, Theme } from "@material-ui/core";
import { createStyles, IconButton, Button } from "@material-ui/core";
import { IClientVersion } from "../../interfaces";
import Grid, { IGridColumn } from "../grid";
import { TableCellProps } from "react-virtualized";
import EnableIcon from "@material-ui/icons/StarOutlined";
import { AppDispatch } from "../../context";
import { getClientVersions, activateClientVersions } from "../../api/version";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flex: 1,
            height: "calc(100vh - 72px)",
            flexDirection: "column"
        },
        card: {
            width: 300,
            margin: "auto"
        },
        gridDefaultContextRenderer: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        },
        textFieldsWrapper: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column"
        },
        nextButton: {
            float: "right"
        },
        selectFormControl: {
            margin: "8px 0"
        },
        gridWrapper: {
            minHeight: 300,
            flex: 4
        },
        devicesAndTunnelsWrapper: {
            minWidth: 55,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%"
        },
        eyeButton: {
            marginLeft: 10,
            padding: 8,
            [theme.breakpoints.down("sm")]: {
                padding: 2
            }
        }
    });

const ClientVersions = ({ classes }: WithStyles<typeof styles>) => {
    const dispatch = useContext(AppDispatch)!;
    const [clientVersions, setClientVersions] = useState<IClientVersion[]>([]);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const reload = () =>
        getClientVersions()
            .then(response => {
                setIsDataLoading(false);
                const data = (response && response.data) || [];
                setClientVersions(data);
            })
            .catch(e => {
                setIsDataLoading(false);
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });
    useEffect(() => {
        setIsDataLoading(true);
        reload();
    }, []);

    const gridDefaultContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = props => {
        let data = <div className={classes.gridDefaultContextRenderer}>{props.cellData}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={props.cellData} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };
    const activateCellRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = props => (
        <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={async () => {
                try {
                    setIsDataLoading(true);
                    await activateClientVersions(props.rowData._id);
                } catch (e) {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                } finally {
                    reload();
                }
            }}
        >
            Activate
        </Button>
    );
    const columns: IGridColumn[] = [
        {
            dataKey: "_id",
            width: 200,
            label: "ID",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "version",
            width: 30,
            label: "Version",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "checksum",
            width: 200,
            label: "Checksum",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "status",
            width: 30,
            label: "Status",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "buildRevision",
            width: 30,
            label: "BuildRevision",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "path",
            width: 250,
            label: "Path",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "clientType",
            width: 60,
            label: "ClientType",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "platform",
            width: 50,
            label: "Platform",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "architecture",
            width: 50,
            label: "Architecture",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "Activate",
            width: 50,
            label: "Activate",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: activateCellRenderer
        }
    ];

    return (
        <div className={classes.gridWrapper}>
            <Grid
                data={clientVersions}
                columns={columns}
                enablePagination
                gridToolbarTitle="Versions"
                searchValueChangeTimeOutForCall={1000}
                isDataLoading={isDataLoading}
                rowsPerPageOptions={[100, 250, 500]}
                labelRowsPerPage={window.innerWidth > 992 ? undefined : null}
            />
        </div>
    );
};

export default withStyles(styles)(ClientVersions);
