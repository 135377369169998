import React, { useState, useEffect, useContext } from "react";
import { WithStyles, withStyles, Tooltip, Theme, Button, FormControl } from "@material-ui/core";
import { createStyles, IconButton } from "@material-ui/core";
import { IOrganization } from "../../interfaces";
import Grid, { IGridColumn } from "../grid";
import { TableCellProps } from "react-virtualized";
import VisibilityIcon from "@material-ui/icons/Visibility";

import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

import EnableIcon from "@material-ui/icons/StarOutlined";

import { AppDispatch } from "../../context";
import { getOrganizations, addOrganizations } from "../../api/organizations";
import AddOrganizationDialog from "./addOrganizationDialog";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flex: 1,
            height: "calc(100vh - 72px)",
            flexDirection: "column",
        },
        card: {
            width: 300,
            margin: "auto",
        },
        gridDefaultContextRenderer: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        textFieldsWrapper: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
        },
        nextButton: {
            float: "right",
        },
        selectFormControl: {
            margin: "8px 0",
        },
        gridWrapper: {
            minHeight: 300,
            flex: 4,
        },
        devicesAndTunnelsWrapper: {
            minWidth: 55,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%",
        },
        eyeButton: {
            marginLeft: 10,
            padding: 8,
            [theme.breakpoints.down("sm")]: {
                padding: 2,
            },
        },
    });

const Organization = ({ classes }: WithStyles<typeof styles>) => {
    const dispatch = useContext(AppDispatch)!;
    const [organizations, setOrganizations] = useState<IOrganization[]>([]);
    const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const reload = () =>
        getOrganizations({ filters: [{ property: "whiteLabel", value: "all" }] })
            .then((response) => {
                setIsDataLoading(false);
                const data = (response && response.data) || [];
                setOrganizations(data);
            })
            .catch((e) => {
                setIsDataLoading(false);
                dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
            });

    useEffect(() => {
        setIsDataLoading(true);
        reload();
    }, []);

    const gridDefaultContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = (props) => {
        const data = <div className={classes.gridDefaultContextRenderer}>{JSON.stringify(props.cellData)}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={props.cellData} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };

    const columns: IGridColumn[] = [
        {
            dataKey: "_id",
            width: 200,
            label: "ID",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "name",
            width: 200,
            label: "Name",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
        {
            dataKey: "description",
            width: 200,
            label: "Description",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer,
        },
    ];
    const onAddDialogClose = () => {
        setIsAddDialogOpen(false);
    };

    const createOrganization = async ({
        name,
        description,
        _id,
    }: {
        name: string;
        description: string;
        _id?: string;
    }) => {
        setIsAddDialogOpen(false);
        try {
            if (!_id) {
                await addOrganizations(name, description);
                reload();
            }
        } catch (e) {
            reload();
        }
    };

    return (
        <div className={classes.root}>
            <div style={{ margin: "auto" }}>
                <FormControl>
                    <label htmlFor="contained-button-file">
                        <IconButton
                            onClick={() => {
                                setIsAddDialogOpen(true);
                            }}
                        >
                            Add Organization <AddIcon />
                        </IconButton>
                    </label>
                </FormControl>
            </div>
            <div className={classes.gridWrapper}>
                <Grid
                    data={organizations}
                    columns={columns}
                    enablePagination
                    gridToolbarTitle="Organization"
                    searchValueChangeTimeOutForCall={1000}
                    isDataLoading={isDataLoading}
                    rowsPerPageOptions={[100, 250, 500]}
                    labelRowsPerPage={window.innerWidth > 992 ? undefined : null}
                />
                <AddOrganizationDialog
                    isOpen={isAddDialogOpen}
                    onClose={onAddDialogClose}
                    packageInfo={{}}
                    onSave={createOrganization}
                />
            </div>
        </div>
    );
};

export default withStyles(styles)(Organization);
