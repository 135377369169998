import React, { useState, useEffect, useContext } from "react";
import { WithStyles, withStyles, Tooltip, Theme, Button, FormControl, Select } from "@material-ui/core";
import { createStyles, IconButton, InputLabel, MenuItem } from "@material-ui/core";
import { IOrganization } from "../../interfaces";
import Grid, { IGridColumn } from "../grid";
import { TableCellProps } from "react-virtualized";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { getDevicePackages, deleteDevicePackages, addDevicePackages } from "../../api/package";
import { AppDispatch } from "../../context";
import { getOrganizations } from "../../api/organizations";
import AddDeletePackageDialog from "./add-delete-package-dialog";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flex: 1,
            height: "calc(100vh - 72px)",
            flexDirection: "column"
        },
        card: {
            width: 300,
            margin: "auto"
        },
        gridDefaultContextRenderer: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        },
        textFieldsWrapper: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column"
        },
        nextButton: {
            float: "right"
        },
        selectFormControl: {
            margin: "8px 0"
        },
        gridWrapper: {
            minHeight: 300,
            flex: 4
        },
        devicesAndTunnelsWrapper: {
            minWidth: 55,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "100%"
        },
        eyeButton: {
            marginLeft: 10,
            padding: 8,
            [theme.breakpoints.down("sm")]: {
                padding: 2
            }
        }
    });

const DevicePackage = ({ classes }: WithStyles<typeof styles>) => {
    const dispatch = useContext(AppDispatch)!;
    const [devices, setDevices] = useState<any[]>([]);
    const [reload, setReload] = useState<boolean>(false);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [selectedDevice, setSelectedDevice] = useState<string>("");
    const [action, setAction] = useState<"add" | "delete" | "edit" | "">("");
    const [organization, setOrganization] = useState<string>("");
    const [organizations, setOrganizations] = useState<IOrganization[]>([]);
    const handleOrganizationChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setOrganization(String(event.target.value));
    };

    useEffect(() => {
        setIsDataLoading(true);
        (async () => {
            try {
                const data = await getOrganizations({ filters: [{ property: "whiteLabel", value: "all" }] });
                setOrganizations(data.data);
            } catch (e) {
            } finally {
                setIsDataLoading(false);
            }
        })();
    }, []);
    useEffect(() => {
        if (organization) {
            setIsDataLoading(true);
            (async () => {
                try {
                    const data = await getDevicePackages(organization);
                    setDevices(data.data);
                } catch (e) {
                } finally {
                    setIsDataLoading(false);
                }
            })();
        }
    }, [organization, reload]);

    const addDevicePackage = (deviseId: string) => () => {
        setSelectedDevice(deviseId);
        setAction("add");
    };
    const deleteDevicePackage = (deviseId: string) => () => {
        setSelectedDevice(deviseId);
        setAction("delete");
    };
    const editDevicePackage = (deviseId: string) => () => {
        setSelectedDevice(deviseId);
        setAction("edit");
    };

    const gridDefaultContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = props => {
        let data = <div className={classes.gridDefaultContextRenderer}>{props.cellData}</div>;
        if (window.innerWidth < 768) {
            return (
                <Tooltip title={props.cellData} placement="bottom-end">
                    {data}
                </Tooltip>
            );
        }
        return data;
    };
    const packageContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = props => {
        const d = props.cellData.map((f: any) => (f.packageId || { name: 'NA' }).name).join(", ");
        return (
            <Tooltip title={d} placement="bottom-end">
                <div className={classes.gridDefaultContextRenderer}>{d}</div>
            </Tooltip>
        );
    };
    const actionsPackageContextRenderer: (cellRendererProps: TableCellProps) => React.ReactNode = props => {
        return (
            <>
                <IconButton title="Add Packages " onClick={addDevicePackage(props.cellData)}>
                    <AddIcon />
                </IconButton>
                <IconButton title="Delete Packages " onClick={deleteDevicePackage(props.cellData)}>
                    <DeleteIcon />
                </IconButton>
                <IconButton title="Update Package Version " onClick={editDevicePackage(props.cellData)}>
                    <EditIcon />
                </IconButton>
            </>
        );
    };
    const columns: IGridColumn[] = [
        {
            dataKey: "_id",
            width: 200,
            label: "ID",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "name",
            width: 200,
            label: "Name",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: gridDefaultContextRenderer
        },
        {
            dataKey: "packages",
            width: 400,
            label: "Packages",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: packageContextRenderer
        },
        {
            dataKey: "_id",
            width: 400,
            label: "Actions",
            flexGrow: 1,
            flexShrink: 1,
            cellContentRenderer: actionsPackageContextRenderer
        }
    ];
    const addDevicePackagesSave = async (deviceId: string, packageIds: string[]) => {
        try {
            await addDevicePackages(deviceId, packageIds);
        } catch (e) {
            dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
        } finally {
            setReload(!reload);
            setAction("");
        }
    };
    const deleteDevicePackagesSave = async (deviceId: string, packageIds: string[]) => {
        try {
            await deleteDevicePackages(deviceId, packageIds);
        } catch (e) {
            dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
        } finally {
            setReload(!reload);
            setAction("");
        }
    };
    const fn = action === "add" ? addDevicePackagesSave : action === "delete" ? deleteDevicePackagesSave : undefined;
    return (
        <div className={classes.root}>
            <div style={{ margin: "auto" }}>
                <FormControl className={classes.selectFormControl}>
                    <InputLabel htmlFor="architecture">Select Organization</InputLabel>
                    <Select
                        value={organization}
                        onChange={handleOrganizationChange}
                        inputProps={{
                            name: "architecture",
                            id: "architecture"
                        }}
                    >
                        {organizations.map(f => (
                            <MenuItem key={f._id} value={f._id}>
                                {f.name} {f.description}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className={classes.gridWrapper}>
                <Grid
                    data={devices}
                    columns={columns}
                    enablePagination={false}
                    gridToolbarTitle="Packages"
                    searchValueChangeTimeOutForCall={1000}
                    isDataLoading={isDataLoading}
                    rowsPerPageOptions={[100, 250, 500]}
                    labelRowsPerPage={window.innerWidth > 992 ? undefined : null}
                />
                {selectedDevice && ["add", "delete"].indexOf(action) > -1 && (
                    <AddDeletePackageDialog
                        onClose={() => {
                            setAction("");
                        }}
                        action={action}
                        devicePackages={devices
                            .find(d => d._id === selectedDevice)!
                            .packages.map((p: any) => p.packageId._id)}
                        onSave={fn}
                        isOpen={true}
                        deviceId={selectedDevice}
                    />
                )}
            </div>
        </div>
    );
};

export default withStyles(styles)(DevicePackage);
