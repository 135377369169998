import React from "react";
import { TableCellProps } from "react-virtualized";
import {
    createStyles,
    Dialog,
    withStyles,
    WithStyles,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
} from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button/Button";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import HelpIcon from "@material-ui/icons/HelpOutline";
const styles = () =>
    createStyles({
        dialog: {
            "@media (min-width: 600px)": {
                // height: "375px",
                minWidth: "350px",
            },
        },
        dialogContent: {
            padding: 0,
            minHeight: "215px",
            // height: "215px",
            borderTop: "1px solid rgba(80, 76, 76, 0.14)",
        },
        dialogActions: {
            padding: "13px 20px",
            margin: 0,
        },
        emptyPage: {
            padding: "12px 24px",
        },
        nav: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        listItem: {
            paddingLeft: 24,
            paddingRight: 24,
            borderBottom: "1px solid rgba(80, 76, 76, 0.14)",
        },
        listItemText: {
            "&>span": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
        },
        listItemDeleted: {
            backgroundColor: "rgba(0, 0, 0, 0.14)",
        },
    });

interface IInfoDialogProps {
    onClose: () => void;
    title: string;
    data: TableCellProps | null;
    noDataContent: string;
}

const InfoDialog = ({
    classes,
    onClose,
    title,
    data,
    noDataContent,
    fullScreen,
}: WithStyles<typeof styles> &
    IInfoDialogProps & {
        fullScreen?: boolean;
    }) => {
    const determineAvailability = (lastSeen: number) => {
        const newDate = Date.now();

        if (!lastSeen) {
            return "offline";
        }

        const diffMs = newDate - lastSeen;
        const diffMins = Math.round(diffMs / (1000 * 60));
        if (diffMins > 10) {
            return "offline";
        }
        if (diffMins > 3) {
            return "away";
        }
        return "online";
    };
    return (
        <Dialog
            maxWidth="xl"
            open
            classes={{
                paper: classes.dialog,
            }}
            fullScreen={fullScreen}
            onClose={onClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {!!data && !!data.cellData && !data.cellData.length ? (
                    <div className={classes.emptyPage}>{noDataContent}</div>
                ) : (
                    <>
                        <Table stickyHeader style={{ minWidth: 600 }} aria-label="simple table">
                            <TableBody>
                                {data &&
                                    data.cellData.map((item: any) => {
                                        const info = `Platform = ${item.info?.platform} 
                                                              Distro= ${item.info?.distro} 
                                                              Arch =  ${item.info?.arch}  `;
                                        return (
                                            <TableRow
                                                key={item._id}
                                                style={item.deleted ? { backgroundColor: "grey" } : {}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {item.name}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div style={{ display: "flex", flex: 1 }}>
                                                        {determineAvailability(item.statusInfo?.lastSeen)}
                                                        <Tooltip
                                                            style={{ marginLeft: 10 }}
                                                            title={info}
                                                            placement="bottom"
                                                        >
                                                            <HelpIcon fontSize="small" color="primary" />
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </>
                )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withMobileDialog<IInfoDialogProps>()(withStyles(styles)(InfoDialog));
