import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { WithStyles } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import MessageExplorer from "../message-explorer";
import Emails from "../emails";
import EmailDomains from "../email-domains";
import TunnelEngines from "../tunnel-engines";
import Users from "../users";
import Header from "../header";
import Authorities from "../authorities";
import Roles from "../roles";
import Packages from "../packages";
import ClientVersions from "../versions";
import Organization from "../organization";
import DevicePackage from "../device-package";
import CouponCode from "../coupon-code";

const styles = createStyles({
    appRootWrapper: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
});

const Main = (props: WithStyles<typeof styles>) => {
    const {
        classes: { appRootWrapper },
    } = props;
    return (
        <div className={appRootWrapper}>
            <Header />
            <Switch>
                <Route path="/message-explorer">
                    <MessageExplorer />
                </Route>
                <Route exact path="/coupons">
                    <CouponCode />
                </Route>
                <Route path="/emails">
                    <Emails />
                </Route>
                <Route path="/email-domains">
                    <EmailDomains />
                </Route>
                <Route path="/tunnel-engines">
                    <TunnelEngines />
                </Route>
                <Route path="/authorities">
                    <Authorities />
                </Route>
                <Route path="/users">
                    <Users />
                </Route>
                <Route path="/roles">
                    <Roles />
                </Route>
                <Route path="/packages">
                    <Packages />
                </Route>
                <Route path="/clientVersions">
                    <ClientVersions />
                </Route>
                <Route path="/orgs">
                    <Organization />
                </Route>
                <Route path="/devicePackage">
                    <DevicePackage />
                </Route>
                <Route exact path="/">
                    <MessageExplorer />
                </Route>

                <Redirect to="/" />
            </Switch>
        </div>
    );
};

export default withStyles(styles)(Main);
