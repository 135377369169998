import { BASE_DOMAIN } from "../constants/app";
import { makeGet, makePatch } from "./index";

const API_URL = {
    GET_ALL_METHODS: () => `${BASE_DOMAIN}/v1/methods`,
    GET_ALL_METHODS_WITH_ROLES: () => `${BASE_DOMAIN}/v1/methods-statistics`
};

export function getRolesAllMethods() {
    return makeGet(API_URL.GET_ALL_METHODS(), {});
}

export function getAllMethodsWithRoles() {
    return makeGet(API_URL.GET_ALL_METHODS_WITH_ROLES(), {});
}
