import React, { useState } from "react";
import {
    Dialog,
    TextField,
    withStyles,
    WithStyles,
    FormControl,
    InputLabel,
    Select,
    DialogActions
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, Button, MenuItem, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { createOrUpdatePackageFilter } from "../../api/package";
import { async } from "q";
const osNames = [
    "all",
    "windows",
    "apple",
    "arch",
    "centos",
    "coreos",
    "debian",
    "deepin",
    "elementary",
    "fedora",
    "gentoo",
    "mageia",
    "mandriva",
    "manjaro",
    "mint",
    "openbsd",
    "freebsd",
    "opensuse",
    "pclinuxos",
    "puppy",
    "raspbian",
    "reactos",
    "redhat",
    "slackware",
    "sugar",
    "steam",
    "suse",
    "ubuntu-mate",
    "lubuntu",
    "xubuntu",
    "ubuntu",
    "solaris",
    "tails",
    "robolinux"
];

const styles = createStyles({
    card: {
        width: 300,
        height: 300
    },
    textFieldsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    nextButton: {
        float: "right"
    },
    selectFormControl: {
        margin: "8px 0"
    }
});
const AddFilterDialog = ({
    classes,
    isOpen,
    onClose,
    onSave,
    packageId
}: WithStyles<typeof styles> & {
    isOpen: boolean;
    packageId: string;
    onClose: () => void;
    onSave: () => void;
}) => {
    const [fileList, setFileList] = useState<FileList | null>(null);
    const [actions, setActions] = useState<Array<{ index: number; name: string; value: string }>>([
        { index: 1, name: "", value: "" }
    ]);
    const [os, setOs] = useState<string[]>(["all"]);
    const [architecture, setArchitecture] = useState<string[]>(["all"]);
    const [platform, setPlatform] = useState<string[]>(["all"]);
    const handelFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileList(event.target.files);
    };
    const handleOsChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        setOs((event.target.value as any).filter((f: any) => !!f));
    };
    const handlePlatformChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        setPlatform((event.target.value as any).filter((f: any) => !!f));
    };
    const handleArchitectureChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        setArchitecture((event.target.value as any).filter((f: any) => !!f));
    };
    const handelRemoveAction = (i: any) => () => {
        actions.splice(actions.indexOf(i), 1);
        setActions([...actions]);
    };

    const handelAddAction = () => {
        const index = actions.reduce((p, c) => (c.index >= p ? c.index : p), 1) + 1;
        actions.push({ index, name: "", value: "" });
        setActions([...actions]);
    };
    const onActionChange = (name: string, value: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
        value[name] = event.target.value;
        setActions([...actions]);
    };

    const onCreateBtnClick = async () => {
        const data: FormData = new FormData();
        const filter: any = {
            arch: architecture,
            platform,
            os,
            actions
        };
        data.append("filter", JSON.stringify(filter));
        if (fileList) {
            for (let index = 0; index < fileList.length; index++) {
                const element = fileList[index];
                data.append(element.name, element as any, element.name);
            }
        }
        try {
            await createOrUpdatePackageFilter(packageId, data);
        } catch (e) { }

        onSave();
    };
    // there are error in @types IconButton do not accept  component props but it exist in parent class
    // @see https://material-ui.com/api/button-base/
    const ButtonWrapper: any = Button;
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Add Package</DialogTitle>
            <DialogContent>
                <div className={classes.card}>
                    <div className={classes.card}>
                        <div className={classes.textFieldsWrapper}>
                            <FormControl className={classes.selectFormControl}>
                                <InputLabel htmlFor="architecture">Architecture</InputLabel>
                                <Select
                                    value={architecture}
                                    onChange={handleArchitectureChange}
                                    multiple={true}
                                    inputProps={{
                                        name: "architecture",
                                        id: "architecture"
                                    }}
                                >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value="x64">x64</MenuItem>
                                    <MenuItem value="x86">x86</MenuItem>
                                    <MenuItem value="arn7">arn7</MenuItem>
                                    <MenuItem value="arn6">arn6</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.selectFormControl}>
                                <InputLabel htmlFor="platform">Platform</InputLabel>
                                <Select
                                    value={platform}
                                    onChange={handlePlatformChange}
                                    multiple={true}
                                    inputProps={{
                                        name: "platform",
                                        id: "platform"
                                    }}
                                >
                                    {[
                                        "all",
                                        "aix",
                                        "android",
                                        "darwin",
                                        "freebsd",
                                        "linux",
                                        "openbsd",
                                        "sunos",
                                        "win32",
                                        "cygwin",
                                        "other"
                                    ].map(f => {
                                        return (
                                            <MenuItem key={f} value={f}>
                                                {f[0].toUpperCase() + f.substr(1)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.selectFormControl}>
                                <InputLabel htmlFor="os">OS</InputLabel>
                                <Select
                                    value={os}
                                    onChange={handleOsChange}
                                    multiple={true}
                                    inputProps={{
                                        name: "os",
                                        id: "os"
                                    }}
                                >
                                    {osNames.map(f => {
                                        return (
                                            <MenuItem key={f} value={f}>
                                                {f[0].toUpperCase() + f.substr(1)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <input
                                    id="contained-button-file-s"
                                    multiple
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={handelFileChange}
                                />
                                <label htmlFor="contained-button-file-s">
                                    <ButtonWrapper variant="contained" component='span'>Upload</ButtonWrapper>
                                </label>
                            </FormControl>
                            <FormControl>
                                <label> Actions</label>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    {actions.map(i => (
                                        <div
                                            key={i.index}
                                            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                                        >
                                            <TextField
                                                id="standard-multiline-flexible"
                                                label="Name"
                                                multiline
                                                value={i.name}
                                                rowsMax="4"
                                                onChange={onActionChange("name", i)}
                                                margin="normal"
                                            />
                                            <div> </div>
                                            <TextField
                                                id="standard-name"
                                                label="Command"
                                                onChange={onActionChange("value", i)}
                                                value={i.value}
                                                margin="normal"
                                            />
                                            <Fab color="primary" aria-label="Add" style={{ height: 25 }}>
                                                <AddIcon onClick={handelAddAction} style={{ height: 25 }} />
                                            </Fab>
                                            <Fab color="primary" aria-label="Delete" style={{ height: 25 }}>
                                                <DeleteIcon onClick={handelRemoveAction(i)} style={{ height: 25 }} />
                                            </Fab>
                                        </div>
                                    ))}
                                </div>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button className={classes.nextButton} onClick={onClose}>
                    Cancel
                </Button>
                <Button className={classes.nextButton} onClick={onCreateBtnClick}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default withStyles(styles)(AddFilterDialog);
