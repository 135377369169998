import React, { useState, useContext, useEffect, memo } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Theme, WithStyles } from "@material-ui/core";

import { validateEmail, validatePassword, EMPTY, INVALID_EMAIL, INVALID_PASSWORD } from "../../utils/validationsUtil";
import { useFormInput } from "../../utils/hooks";
import { login, saveAuthToken } from "../../api";
import { AppDispatch } from "../../context";

const styles = ({ spacing }: Theme) =>
    createStyles({
        main: {
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center"
        },
        card: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        cardContent: {
            padding: "20px"
        },
        cardContentBody: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        actionsBar: {
            display: "flex",
            flexDirection: "column",
            padding: "5px 0px",
            height: "70px",
            alignItems: "inherit"
        },
        flex1: {
            flex: "1"
        },
        formControl: {
            marginTop: spacing(1),
            marginBottom: spacing(1)
        },
        loginButton: {
            // margin: theme.spacing(1)
            marginRight: "16px"
        },
        mainActions: {
            display: "flex",
            flexDirection: "row",
            height: "36px"
        },
        passwordRecoveryLink: {
            padding: "0",
            fontSize: "11px",
            height: "30px",
            minHeight: "30px",
            marginRight: "16px",
            marginTop: "4px"
        },
        passwordRecoveryLinkWrapper: {
            display: "flex",
            flexDirection: "row"
        }
    });

function setEmailValidationError(value: string, setter: (a: string) => void) {
    let isValid = validateEmail(value);
    if (isValid === EMPTY) {
        setter("Please enter your email");
    } else if (isValid === INVALID_EMAIL) {
        setter("Invalid Email");
    } else {
        setter("");
    }
    return isValid === true;
}

function setPasswordValidationError(value: string, setter: (a: string) => void) {
    let isValid = validatePassword(value);
    if (isValid === EMPTY) {
        setter("Please enter your password");
    } else if (isValid === INVALID_PASSWORD) {
        setter("Passwords should be min 8 length");
    } else {
        setter("");
    }
    return isValid === true;
}

const Login = (props: WithStyles<typeof styles>) => {
    const {
        classes: { main, card, cardContent, cardContentBody, formControl, actionsBar, mainActions, flex1, loginButton }
    } = props;

    const [loginError, setLoginError] = useState("");
    const [loginInProgress, setLoginInProgress] = useState(false);
    const email = useFormInput("");
    const password = useFormInput("");
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const dispatch = useContext(AppDispatch)!;

    useEffect(() => {
        if (loginInProgress) {
            if (!validateFormFields()) {
                setLoginInProgress(false);
                return;
            }
            let formData = {
                email: email.value,
                password: password.value
            };
            login(email.value, password.value)
                .then(response => {
                    const { data } = response;
                    saveAuthToken(data.authToken);
                    dispatch({ type: "LOGGED_IN_USER", payload: data.user });
                })
                .catch(e => {
                    dispatch({ type: "SHOW_MESSAGE_ERROR", payload: e });
                });
        }
    }, [loginInProgress]);

    function onKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) {
        switch (event.keyCode) {
            case 13: {
                setLoginInProgress(true);
                break;
            }
            default:
                break;
        }
    }

    function validateFormFields() {
        const { value: emailValue } = email;
        const { value: passwordValue } = password;
        let isValid =
            setEmailValidationError(emailValue, setEmailError) &&
            setPasswordValidationError(passwordValue, setPasswordError);

        return isValid;
    }
    return (
        <div className={main}>
            <Card className={card}>
                <CardContent className={cardContent}>
                    <Typography variant="h6" color="inherit">
                        Sign in
                    </Typography>
                    {loginError && (
                        <Typography variant="subtitle1" color="error">
                            {loginError}
                        </Typography>
                    )}
                    <div className={cardContentBody}>
                        <FormControl className={formControl} required error={!!emailError}>
                            <InputLabel htmlFor="login-email-field">Email</InputLabel>
                            <Input
                                id="login-email-field"
                                autoComplete="email"
                                {...email}
                                autoFocus
                                type="email"
                                onKeyDown={onKeyDown}
                            />
                            {emailError && <FormHelperText error>{emailError}</FormHelperText>}
                        </FormControl>
                        <FormControl required className={formControl}>
                            <InputLabel htmlFor="login-password-field">Password</InputLabel>
                            <Input
                                id="login-password-field"
                                type={showPassword ? "text" : "password"}
                                autoComplete="current-password"
                                {...password}
                                onKeyDown={onKeyDown}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            tabIndex={-1}
                                            onMouseDown={e => e.preventDefault()}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {passwordError && <FormHelperText error>{passwordError}</FormHelperText>}
                        </FormControl>
                    </div>
                </CardContent>
                <CardActions className={actionsBar}>
                    <div className={mainActions}>
                        <div className={flex1} />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={loginInProgress}
                            className={loginButton}
                            onClick={() => setLoginInProgress(true)}
                        >
                            Login
                        </Button>
                    </div>
                </CardActions>
            </Card>
        </div>
    );
};

Login.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(memo(Login));
